.div-form-drink-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  margin-top: 20px;
}
.div-form-drink-icons {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  height: 180px;
}
.img-form-drink-glass {
  width: 40px;
  height: 46px;
}
.img-form-drink-bottle {
  width: 50px;
  height: 160px;
}
