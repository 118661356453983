.div-article-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding-top: 68px;
  overflow: auto;
}
.div-article-header-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9000;
  color: #1e1e1e;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
  padding: 0 12px;
  background-color: white;
}
.div-article-title-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid var(--black-100, #ececec);
  padding: 0 12px;
  padding-bottom: 16px;
}
.div-article-detail-section {
  display: flex;
  align-items: center;
  gap: 8px;
}
.div-article-detail {
  color: var(--black-600, #6a6a6a);
  font-size: 12px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 2px;
}
.pre-article-content {
  padding: 16px 12px;
  white-space: pre-wrap;
}
.img-article {
  margin: 6px 12px;
  width: calc(100% - 24px);
  border-radius: 8px;
}
.div-article-thumbs-section {
  width: 100%;
  padding: 20px 0 34px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.button-article-thumbs {
  display: flex;
  width: 80px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--primary, #ff7a00);
}
.div-article-reply-cnt {
  display: flex;
  width: 320px;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;

  overflow: hidden;
  color: var(--black-800, #333);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.div-article-reply-section {
  display: flex;
  padding: 8px 12px 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid var(--black-100, #ececec);
}
.div-article-reply-name {
  display: flex;
  gap: 8px;
  color: var(--black-600, #6a6a6a);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.pre-article-reply-content {
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-wrap;
}
.div-article-reply-reply-section {
  display: flex;
  padding: 8px 12px 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: var(--black-50, #f8f8f8);
  border-bottom: 1px solid var(--black-100, #ececec);
}
.div-article-reply-reply-section .div-article-reply-content {
  margin-left: 16px;
}
.div-article-reply-reply-section .div-article-detail-section {
  margin-left: 16px;
}

.div-article-write-reply {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 8px 12px 24px 12px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  border-top: 1px solid var(--black-100, #ececec);
  background: var(--white, #fff);
}
.div-article-write-reply textarea {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  background: var(--black-50, #f8f8f8);
  resize: none;
  flex-shrink: 1;
}

.div-article-write-reply input::placeholder {
  color: var(--black-400, #9f9f9f);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.div-article-write-reply button {
  flex-shrink: 0;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.div-article-write-reply .button-article-reply-anonymous {
  padding: 8px 0;
  gap: 2px;
  color: #6a6a6a;
}
.div-article-write-reply .button-article-reply-anonymous-checked {
  padding: 8px 0;
  gap: 2px;
  color: #ff7a00;
}
.button-article_delete-article {
  width: 45px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--primary-selected, rgba(255, 122, 0, 0.3));
  color: #ff7a00;
}
