.input-poster {
  display: flex;
  align-items: center;
  gap: 15px;
}
.input-poster #thumb {
  z-index: 1;
}
.input-poster-info {
  display: flex;
  align-items: center;
  gap: 3px;
}
.input-poster-info > p {
  position: relative;
  color: #ff7a00;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
  white-space: nowrap;
}
.input-poster-info > img {
  filter: invert(50%) sepia(82%) saturate(2596%) hue-rotate(2deg)
    brightness(103%) contrast(106%);
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.input-poster input[type="file"] {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.input-poster input[type="button"] {
  width: 109.5px;
}
.input-poster #thumb {
  position: absolute;
  width: 75px;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  transform: translateY(-50%);
}
::placeholder {
  color: #cdcdcd;
}
/* 공고 작성_비면접 선택 */
.div-recruit-wrapper {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 725.25px;
  background: #ffffff;
  border-radius: 8px;
}
#recruit-header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 36px;
}
#recruit-header-right {
  display: flex;
  gap: 34px;
  align-items: center;
}
#recruit-header img {
  width: 21px;
  height: 21px;
  cursor: pointer;
}
#recruit-header button {
  padding: 6px 9px;
  background-color: var(--White);
  border-radius: 8px;
}
#popup-tmp-save {
  position: absolute;
  top: 42px;
  right: 184.5px;
  transform: translateY(-50%);
}
.div-recruit-questions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
}
.div-recruit-question {
  display: flex;
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
}
.recruit-label-wrapper {
  width: 165px;
  display: flex;
  align-items: center;
  gap: 3px;
}
.img-recruit-info {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
}
.popup-recruit-info {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  max-width: 170px;
  /* top: 25px; */
  z-index: 90;
  word-break: keep-all;
}
.recruit-label-wrapper > label {
  padding: 9px 0px;
  text-align: left;
}
.div-recruit-question-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.input-recruit-headline {
  width: 369px;
  height: 33px;
}
.input-recruit-headline:focus {
  border: 2px solid black;
}
.input-recruit-contents {
  width: 369px;
  height: 93px;
  overflow-y: scroll;
  resize: none;
}
.button-recruit {
  padding: 9px 12px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
}
.button-recruit:hover {
  filter: brightness(90%);
}
.div-recruit-calendars-wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}
.div-recruit-selections {
  display: flex;
  gap: 27px;
}
.recruit-selection {
  display: none;
}
.recruit-selection + label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.recruit-selection + label img {
  width: 18px;
}
#recruit-vertical-devider {
  border: 1px solid var(--Black100);
  width: 100%;
  height: 0px;
}
#recruit-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 18px 36px;
}
#recruit-footer-left {
  display: flex;
  gap: 9px;
  align-items: center;
  cursor: pointer;
}
#recruit-footer img {
  width: 18px;
}
#recruit-footer button {
  width: 120px;
  height: 33px;
}
#recruit00-wrapper {
  position: absolute;
  right: 18px;
  top: 54px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 18px 0px 36px;
  background-color: white;
  border-radius: 8px;
}
#recruit00-DefaultImgs {
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
  padding: 0 12px;
}
#recruit00-DefaultImgs img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 100%;
}
#recruit00-DefaultImgsM {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0 16px;
}
#recruit00-DefaultImgsM img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 100%;
}
#recruit01-wrapper {
  position: absolute;
  right: 18px;
  top: 54px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 300px;
  height: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 18px 15px 22.5px;
}
#recruit01-close {
  cursor: pointer;
  width: 18px;
  position: absolute;
  top: 18px;
  right: 18px;
}
#recruit01-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
}
#recruit01-textBtns {
  margin-top: 24px;
  display: flex;
}
.div-recruit01-textBtn {
  padding: 4.5px 7.5px;
  cursor: pointer;
}
#div-recruit01-selections {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 6px;
  width: 100%;
  height: 172.5px;
}
#div-recruit01-selections::-webkit-scrollbar {
  display: none;
}
.div-recruit01-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16.5px;
  padding: 9px 15px;
  background-color: #f8f8f8;
  width: 270px;
  /* height: 49.5px; */
  border-radius: 8px;
  cursor: pointer;
}
.div-recruit01-selection-left {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
}
.div-recruit01-selection-left p {
  text-align: left;
}
.div-recruit01-selection img {
  width: 15px;
  height: 15px;
}
#recruit01-footer {
  width: 255px;
  position: absolute;
  bottom: 0px;
  margin-bottom: 22.5px;
}
