.div-myclub-infobox-section {
  position: absolute;
  top: -12px;
  left: 12px;
  width: calc(100% - 24px);
  height: 156px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.div-myclub-infobox-logo-text {
  display: flex;
  gap: 16px;
}
.div-myclub-infobox-text-section {
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.div-myclub-menu-section {
  position: absolute;
  top: 149px;
  left: 12px;
  width: calc(100% - 24px);
  display: flex;
  border-bottom: 1px solid #ececec;
}
.div-myclub-contents-section {
  position: absolute;
  top: 205px;
  left: 0;
  width: 100%;
}
.div-myclub-infobox-section button {
  padding: 12px 16px;
  border-radius: 22px;
}
.div-myclub-noclub-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  color: var(--black-600, #6a6a6a);
  text-align: center;
}

.button-myclub-noclub {
  padding: 12px 16px;
  border-radius: 29px;
}
