.div-schedule-date-section {
  border-radius: 4px;
  width: 44px;
  height: 44px;
  border: 1px solid var(--black-100, #ececec);
  background: var(--white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div-schedule-date-section t {
  color: var(--primary, #ff7a00);
}
