.div-bottom-sheet-popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.div-bottom-sheet-popup-section {
}

.div-bottom-sheet-popup-list-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40vh;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}
.div-bottom-sheet-popup-list-section::-webkit-scrollbar {
  display: none;
}
.div-bottom-sheet-popup-list-title {
  position: sticky;
  top: 0;
  height: 52px;
  padding-top: 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.div-bottom-sheet-popup-list {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.div-bottom-sheet-popup-list:hover {
  background: #f8f8f8;
  color: #ff7a00;
}

.div-bottom-sheet-popup-list-myclub {
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.div-bottom-sheet-popup-list-myclub:hover {
  background: #f8f8f8;
  color: #ff7a00;
}
.div-bottom-sheet-popup-list-myclub img {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.div-bottom-sheet-popup-list-myclub-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
