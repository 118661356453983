.div-home-header-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 0 12px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}
.img-home-header-logo {
  height: 20px;
}
.div-home-header-search-notification-section {
  display: flex;
  gap: 12px;
  justify-content: right;
  width: 200px;
}
.div-home-header-search {
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 8px 8px 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: var(--black-50, #f8f8f8);
}
.div-home-header-search input {
  flex-grow: 0;
  width: 100%;
  background: none;
  border: none;
}
.div-home-header-search img {
  width: 20px;
  height: 20px;
}

.img-home-header-notification {
  height: 24px;
}

.div-home-section {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
}
.div-home-event-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-home-event-section .img-event {
  width: 100%;
  border-radius: 8px;
}
.div-home-intro-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.div-home-intro-section button {
  padding: 12px 16px;
  border-radius: 29px;
}
.div-home-intro-section .div-menu-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 12px 24px;
  gap: 40px;
  border-radius: 8px;
  background: var(--black-50, #f8f8f8);
}
.div-home-intro-section .div-menu-section .div-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.div-home-intro-section .div-menu-section .div-menu img {
  width: 40px;
}
.div-home-title {
  width: 100%;
}
.div-home-article-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.div-home-menu-section {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ececec;
}
