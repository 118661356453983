.div-navigation-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;

  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;

  background: #ffffff;
  border-top: 1px solid #ececec;
}

.div-navigation-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9f9f9f;
}
.div-navigation-menu-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ff7a00;
}
