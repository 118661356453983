.button-chip-status-0M {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 24px;
  background: rgba(255, 222, 103, 0.3);
  border-radius: 10px;
  color: #ff7a00;
  border: none;
}

.button-chip-status-1M {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 24px;
  background: #ff7a00;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
.button-chip-status-2M {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 24px;
  background: #1e1e1e;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}
