.div-write-article-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding: 68px 12px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 8px;
}
.div-write-article-header-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 12px;
  height: 56px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9000;
  color: #1e1e1e;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
}
.div-write-article-section input {
  width: 100%;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-200, #cdcdcd);
}
.div-write-article-section textarea {
  width: 100%;
  height: calc(100% - 122px);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-200, #cdcdcd);
  resize: none;
  flex-shrink: 0;
}
.button-write-article {
  position: fixed;
  bottom: 12px;
  width: calc(100% - 24px);
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-write-article-photo-title {
  margin-top: 12px;
  color: var(--black-800, #333);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.div-write-article-photo-section {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  flex-shrink: 0;
}
.div-write-article-photo {
  position: relative;
  width: 73px;
  height: 73px;
  flex-shrink: 0;
}
.div-write-article-photo .image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: scale-down;
}
.div-write-article-photo .close {
  position: absolute;
  top: 0;
  right: 0;
}
.button-write-article-anonymous {
  padding: 8px 0;
  gap: 2px;
  color: #6a6a6a;
  display: flex;
  align-items: center;
}
.button-write-article-anonymous-checked {
  padding: 8px 0;
  gap: 2px;
  color: #ff7a00;
  display: flex;
  align-items: center;
}
