.div-question-policy-container-edit {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.textarea-question-policy-request-edit {
  margin-bottom: 8px;
  padding: 9px 12px;
  width: 100%;
  resize: none;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  word-break: break-all;
  height: 200px;
}
.textarea-question-policy-request-show-edit {
  margin-bottom: 8px;
  padding: 9px 0;
  width: 100%;
  resize: none;
  border: none;
  border-radius: 8px;
  word-break: break-all;
  height: 300px;
  background: #f8f8f8;
  padding: 9px 12px;
}
.div-question-policy-response-edit {
  display: flex;
  justify-content: center;
}
.input-question-policy-checkbox-edit {
  display: none;
}
.input-question-policy-checkbox-edit + label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
