.timepickereq-wrapper {
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  gap: 36px;
  position: relative;
}
.timepickereq-middlebox {
  width: 100%;
  height: 28px;
  position: absolute;
  top: 48px;
  left: 0px;
  background: #f8f8f8;
  border-radius: 8px;
}
.timepickereq-columns {
  display: flex;
  align-items: center;
  gap: 4px;
}
.timepickereq-column1 {
  height: 130px;
  width: 30px;
}
.timepickereq-column2 {
  height: 130px;
  width: 20px;
}
.timepickereq-value {
  color: gainsboro;
  text-align: center;
}
.text-black {
  color: black;
}
.timepickereq-word {
  height: 26px;
  z-index: 2;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
