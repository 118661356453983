.div-viewform-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#viewform-modalM {
  position: fixed;
  top: 48px;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; */

  padding: 0 20px 60px 20px;
}
.div-viewformM-arrow-section {
  position: fixed;
  bottom: 20px;
  display: flex;
  gap: 8px;
  width: calc(100% - 40px);
}
.viewform-arrow-leftM {
  width: 100%;
  padding: 9px 12px;
  border-radius: 22px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 0.75px solid #1e1e1e;
}
.viewform-arrow-rightM {
  width: 100%;
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 22px;
}

#viewform-scroll {
  margin-top: 10px;
  height: 100%;
  overflow-y: auto;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
  padding-bottom: 67px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#viewform-scroll::-webkit-scrollbar {
  display: none;
}

#viewform-scroll input {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
}
#viewform-headerM {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#viewform-headerM img {
  position: absolute;
  right: 20px;
  width: 20px;
  cursor: pointer;
}
.viewform-border {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
}
/* .viewform-border-chip {
  width: 90px;
  padding: 6px 0px;
  text-align: center;
  color: white;
  border-radius: 17px;
} */
.viewform-basicInfo-rowM {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.viewform-basicInfo-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  width: 100%;
}
.viewform-statM {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  min-height: 35px;
}
.viewform-statM > p {
  width: 60px;
  text-align: start;
}
.viewform-basicInfo-answerM {
  width: 150px;
  height: 35px;
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
  display: flex;
  align-items: center;
}
.viewform-moreInfo-columnM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  width: 100%;
}
.viewform-moreInfo-question {
  width: 100%;
  display: flex;
  gap: 9px;
  flex-direction: column;
}
.viewformm-moreInfo-question-title {
  margin-top: 4px;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
}
.viewform-moreInfo-question > p {
  text-align: start;
}
/* .viewform-moreInfo-question>textarea {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
  resize: none;
  overflow: scroll;
  flex-grow: 1;
} */
.viewform-moreInfo-answer-defaultM {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.viewform-moreInfo-answer-image {
  width: 168px;
  height: 168px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f8f8f8;
  object-fit: contain;
  cursor: pointer;
}
.viewform-moreInfo-answer-file {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e1e1e;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  gap: 8px;
  width: 210px;
  height: 33px;
  cursor: pointer;
}
.viewform-moreInfo-answer-file img {
  width: 15px;
  height: 15px;
}
#viewform-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 18px 60px;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.viewform-memoBtn {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}
.viewform-memoBtn img {
  width: 18px;
  height: 18px;
}
.viewform-memo {
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  width: 100%;
  height: 108px;
  padding: 9px 12px;
  resize: none;
}
