.ToastPopup {
  display: flex;
  align-items: center;
  padding: 6.5px 9px;
  /* height: 24px; */
  gap: 9px;
  border-radius: 8px;
  cursor: default;
}
.ToastPopup p {
  width: 200px;
}
.ToastPopup img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ToastPopup-error {
  background-color: var(--Error50);
  color: var(--Error100);
}
.ToastPopup-error img {
  filter: invert(34%) sepia(37%) saturate(5341%) hue-rotate(358deg)
    brightness(96%) contrast(114%);
}
.ToastPopup-warning {
  background-color: var(--Notice50);
  color: var(--Primary);
}
.ToastPopup-warning {
  filter: invert(50%) sepia(82%) saturate(2596%) hue-rotate(2deg)
    brightness(103%) contrast(106%);
}
.ToastPopup-success {
  background-color: var(--Success50);
  color: var(--Success100);
}
.ToastPopup-success img {
  filter: invert(82%) sepia(41%) saturate(5640%) hue-rotate(130deg)
    brightness(97%) contrast(102%);
}
.ToastPopup-info {
  background-color: var(--Black900);
  color: var(--White);
}
.ToastPopup-info img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
.ToastPopup-Gray_info {
  background-color: var(--Black600);
  color: white;
}
.ToastPopup-Gray_info img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
