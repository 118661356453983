::placeholder {
  color: #cdcdcd;
}
#div-createClub-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 725.25px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
#createClub-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 36px;
}
#createClub-header-right {
  display: flex;
}
#createClub-header img {
  width: 21px;
  margin-left: 36px;
  cursor: pointer;
}
#recruit-header button {
  padding: 6px 9px;
  background-color: var(--White);
  border-radius: 8px;
  margin-left: 18px;
}
#form-createClub-questions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
}
.div-createClub-question {
  display: flex;
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.div-createClub-question > p {
  width: 180px;
  padding: 9px 0px;
  text-align: left;
}
.div-createClub-question > label {
  width: 135px;
  padding: 9px 0px;
  text-align: left;
}
.div-createClub-question-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.input-createClub-inputText {
  width: 384px;
  height: 33px;
}
.input-createClub-inputText:focus {
  border: 2px solid black;
}
.button-createClub-uploadImg {
  width: 110px;
  height: 33px;
  padding: 9px 12px;
  background-color: black;
  color: white;
  border-radius: 8px;
}
.div-createClub-subImgTags {
  margin-left: 18px;
  display: flex;
  align-items: flex-start;
  gap: 0 15px;
  flex-wrap: wrap;
  width: 210px;
}
.div-createClub-subImgTag {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  position: relative;
}
.div-createClub-subImgTag > p {
  position: relative;
  color: #ff7a00;
  text-overflow: ellipsis;
  width: 75px;
  white-space: nowrap;
  text-align: start;
  overflow: hidden;
}
.div-createClub-subImgTag-close {
  filter: invert(50%) sepia(82%) saturate(2596%) hue-rotate(2deg)
    brightness(103%) contrast(106%);
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.div-createClub-subImgTag-thumb {
  /* width: 100px + 20px(left padding) */
  width: 120px;
  height: 100px;
  object-fit: contain;
  position: absolute;
  right: 0;
  padding-left: 20px;
  transform: translateX(100%);
  z-index: 1;
  pointer-events: none;
}
.div-createClb-sub
/* .input-createClub-contents {
  width: 384px;
  height: 93px;
  overflow-y: scroll;
  resize: none;
} */
.button-createClub {
  padding: 9px 12px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
}
.button-createClub:hover {
  filter: brightness(90%);
}
.div-createClub-selections {
  display: flex;
  gap: 27px;
}
.createClub-selection {
  display: none;
}
.createClub-selection + label {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}
.createClub-selection + label img {
  width: 18px;
}
#createClub-footer {
  padding: 18px 36px;
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#createClub-submit {
  display: flex;
  align-items: center;
}
#createClub-submit > p {
  width: 87px;
}

.div-createClub-dropdown-wrapper {
  position: relative;
}
.div-createClub-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  width: 130.5px;
  overflow: hidden;
}
.div-createClub-dropdown-box {
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  cursor: text;
  border: none;
}
.img-createClub-dropdown-arrow {
  width: 18px;
  cursor: pointer;
}
.div-createClub-dropdown-scroll {
  z-index: 100;
  height: 165px;
  background: #ffffff;
  overflow-y: scroll;
}
.div-createClub-dropdown-selection {
  width: 100%;
  height: 33px;
  text-align: left;
  padding: 9px 12px;
}
.div-createClub-dropdown-selection:hover {
  background: #ececec;
}

.div-createClub-uploadImg {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
#div-createClub-wrapper input[type="file"] {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  position: absolute;
}
.div-createClub-image-wrapper {
  display: flex;
  cursor: pointer;
}
.div-createClub-image-wrapper img {
  width: 33px;
  height: 33px;
  object-fit: contain;
  border-radius: 8px;
}
.div-createClub-deleteImg {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  width: 33px;
  height: 33px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-createClub-deleteImg img {
  width: 18px;
  height: 18px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
.div-createClub-images {
  display: flex;
  gap: 6px;
}
.chip-createClub {
  padding-right: 6px;
  padding-left: 6px;
}
