.div-managemessage-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  padding: 24px 36px;
  gap: 15px;
}
.div-managemessage-title {
  display: flex;
  align-items: center;
  gap: 9px;
  justify-content: space-between;
}
.div-managemessage-sub {
  width: 750px;
}
.div-managemessage-message-group-section {
  width: calc(100vw - 361.5px - 72px - 36px);
  display: flex;
  gap: 18px;
  overflow: auto;
  padding-bottom: 6px;
}
.div-managemessage-message-group-section-wide {
  width: calc(100vw - 61.5px - 72px - 36px - 18px);
  display: flex;
  gap: 18px;
  overflow: auto;
  padding-bottom: 6px;
}
.div-managemessage-message-section {
  width: 460.5px;
  height: 475px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 18px 24px;
  flex-shrink: 0;
  position: relative;
}
.div-managemessage-message-select-section {
  margin-top: 18px;
  display: flex;
  gap: 15px;
}
.div-managemessage-message-select-section-01 {
  display: flex;
  align-items: center;
  gap: 3px;
}
.div-managemessage-message-tag-section {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  background: #f8f8f8;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  padding: 6px 12px;
  color: #808080;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.div-managemessage-message-tag-chip-section {
  display: flex;
  gap: 6px;
}
.textarea-managemessage-message-content-section {
  width: 100%;
  height: 270px;
  margin-top: 7.5px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  border-radius: 8px;
  padding: 6px 12px;
  vertical-align: top;
  word-break: break-all;
  resize: none;
  overflow: auto;
}
.textarea-managemessage-message-content-section:focus {
  outline: none;
}

.button-managemessage-message {
  width: 120px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 18px;
  right: 24px;
}
.textarea-managemessage-message-content-section-pass {
  width: 100%;
  height: 111px;
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  border-radius: 8px;
  padding: 6px 12px;
  resize: none;
  overflow: auto;
}
.textarea-managemessage-message-content-section-pass:focus {
  outline: none;
}
.textarea-managemessage-message-content-section-fail {
  width: 100%;
  height: 111px;
  margin-top: 6px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  border-radius: 8px;
  padding: 6px 12px;
  resize: none;
  overflow: auto;
}
.textarea-managemessage-message-content-section-fail:focus {
  outline: none;
}
.div-managemessage-message-content-section-title {
  color: #808080;
  width: 100%;
  text-align: left;
  margin-top: 6px;
}
.div-managemessage-copy-chip {
  padding: 3px 6px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.popup-managemessage-info {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 165px;
  word-break: keep-all;
}
