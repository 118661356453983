.div-club-main-background {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 12px;
  margin-bottom: 100px;
}

.div-club-main-schedule-section {
  width: 100%;
  display: flex;
  padding: 8px 12px 16px 12px;
  gap: 12px;

  border-radius: 8px;
  border-bottom: 1px solid var(--black-100, #ececec);
  background: var(--black-50, #f8f8f8);

  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.div-club-main-schedule-section .div-right-section {
  flex-grow: 1;
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
}

.div-right-section .div-dday {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 10px;
  background: var(--black-900, #1e1e1e);
  color: var(--white, #fff);
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  width: 40px;
}
.div-right-section .div-title {
  width: 100%;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div-right-section .div-detail {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
