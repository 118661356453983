* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  user-select: none;
}

html,
body {
  font-family: "Pretendard";
  margin: 0;
  width: 100%;
  height: 100%;
}

pre {
  user-select: text !important;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
:root {
  /* font-color-- start */

  --Black: #000000;
  --Black900: #1e1e1e;
  --Black700: #4c4c4c;
  --Black600: #6a6a6a;
  /* --Black800: ; */
  /* --Black800: ; */
  /* --Black800: ; */
  --Black300: #b4b4b4;
  --Black200: #cdcdcd;
  --Black100: #ececec;
  --White: #ffffff;
  --Primary: #ff7a00;
  /* --Primary-hover: ; */
  /* --Primary-disabled ; */
  --Error100: #ff3d00;
  /* --Error50: #ff3d0033; */
  /* --Error50: rgba(255, 61, 0, 0.2); */
  --Error50: #ffd8cc;
  /* --Notice100: ; */
  --Notice50: #ffde674d;
  --Success100: #00dcdc;
  /* --Success50: #00b1a71a; */
  /* --Success50: rgba(0, 177, 167, 0.1); */
  --Success50: #d9f3f2;

  /* font-color-- end */
}
button {
  color: black;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
button:hover {
  /* filter: brightness(90%); */
}

mark {
  color: #ff7a00;
  background: none;
}

.div-click {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.div-click:hover {
  cursor: pointer;
}

input {
  font-family: "Pretendard";
}

textarea {
  font-family: "Pretendard";
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.div-error-section {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.3);
}
.div-error {
  padding: 36px 18px 12px 18px;
  width: 300px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1); */
}
.div-error-message-section {
  width: 100%;
  height: 36px;
  display: flex;
  gap: 4px;
}
.input-error {
  width: 180px;
  padding: 3px 4px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  text-align: center;
}
.button-error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* font-- start */
.H1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.H2 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25.5px;
}
.H3 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
}
.Sub1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.Sub2 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 16.5px;
}
.Sub3 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.Body1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.Body2 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}
.Body3 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 16.5px;
}
.Body4 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.Body5 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 10.05px;
  line-height: 13.5px;
}

.Tag1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10.5px;
  text-align: center;
}
.Tag1M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.Tag2M {
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
/* font-- end */

/* 모바일에서 hover 효과 없애기 */
@media (hover: hover) {
}

/* button-- start (used with Sub3) */
/* require: 너비 */
.main-orange-button {
  background-color: #ff7a00;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  border: 0.75px solid #ff7a00;
}
.main-orange-buttonM {
  background-color: #ff7a00;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  border: 0.75px solid #ff7a00;
}
.main-orange-button:hover {
  background-color: rgba(255, 122, 0, 0.8);
  border: 0.75px solid rgba(255, 122, 0, 0.8);
}
.main-orange-button:disabled {
  background: #ececec;
  border: 0.75px solid #ececec;
}
.main-orange-buttonM:disabled {
  background: #ececec;
  border: 0.75px solid #ececec;
}

.main-black-button {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  border: 0.75px solid #1e1e1e;
}
.main-black-buttonM {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  border: 0.75px solid #1e1e1e;
}
.main-black-button:hover {
  background: #4c4c4c;
  border: 0.75px solid #4c4c4c;
}
.main-black-button:disabled {
  background-color: #ececec;
  color: #ffffff;
  cursor: default;
  border: 0.75px solid #ececec;
}
.main-black-buttonM:disabled {
  background-color: #ececec;
  color: #ffffff;
  cursor: default;
  border: 0.75px solid #ececec;
}
.main-black-button-disabled {
  background-color: #ececec;
  color: #ffffff;
  cursor: default;
}

.outlined-black-button {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 0.75px solid #1e1e1e;
}
.outlined-black-buttonM {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 0.75px solid #1e1e1e;
}
.outlined-black-button:hover {
  background: #ececec;
}
.outlined-black-button:disabled {
  background-color: #ececec;
  color: #ffffff;
  border: 0.75px solid #ececec;
  cursor: default;
}
.outlined-black-buttonM:disabled {
  background-color: #ececec;
  color: #ffffff;
  border: 0.75px solid #ececec;
  cursor: default;
}

.outlined-black-button-selected {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  border: 0.75px solid #000000;
}

.outlined-orange-button {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #ff7a00;
  border: 0.75px solid #ff7a00;
}
.outlined-orange-buttonM {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #ff7a00;
  border: 0.75px solid #ff7a00;
}
.outlined-orange-button:hover {
  background: rgba(255, 122, 0, 0.3);
}
.outlined-orange-button:disabled {
  background-color: #ececec;
  color: #ffffff;
  border: 0.75px solid #ececec;
  cursor: default;
}
.outlined-orange-buttonM:disabled {
  background-color: #ececec;
  color: #ffffff;
  border: 0.75px solid #ececec;
  cursor: default;
}
.outlined-orange-button-selected {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ff7a00;
  color: #ffffff;
  /* border: 0.75px solid #ff7a00; */
}

/* button-- end */

/* chip-- start (used with Body4) */
.chip {
  line-height: 0;
}
.chip-button {
  display: flex;
  align-items: center;
  padding: 3px 9px;
  gap: 6px;
  border: 1px solid #1e1e1e;
  border-radius: 10.5px;
  cursor: pointer;
}
.chip-button img {
  width: 12px;
  height: 12px;
}
.chip-button:hover {
  background: #ececec;
}
.chip-black {
  display: flex;
  padding: 3px 9px;
  gap: 6px;
  color: white;
  background-color: var(--Black900);
  border-radius: 10.5px;
}
.chip-black img {
  width: 12px;
  height: 12px;
}
.chip-orange {
  display: flex;
  padding: 3px 9px;
  gap: 6px;
  color: white;
  background-color: var(--Primary);
  border-radius: 10.5px;
}
.chip-orange img {
  width: 12px;
  height: 12px;
}
/* chip-- end */

/* input--start (used with Body4) */
/* require: width, overflow */
.-input-box {
  padding: 12px 16px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  cursor: text;
}
.input-box::placeholder {
  color: #cdcdcd;
  color: #cdcdcd;
  /* font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px; */
}
.input-box:disabled {
  background: #f8f8f8;
  color: #cdcdcd;
}
.input-box:invalid {
  border: 1px solid #ff3d00;
}
/* input--end */

/* scrollbar--start */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgba(255, 122, 0, 0.3);
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* scrollbar--end */

.div-right-section {
  background: #f8f8f8;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 100%;
}

.div-header-section {
  height: 61.5px;
  border-bottom: 2px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 1000;
}
.div-header-section-01 {
  display: flex;
  align-items: center;
  position: absolute;
  left: 34.5px;
  flex-shrink: 0;
}

.div-header-section-02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 189px; */
  width: fit-content;
  position: absolute;
  right: 155px;
}
.div-header-section-02-01 {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: right;
}
.button-header-01 {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 122px;
  height: 36px;
  position: absolute;
  right: 18px;
  background: #1e1e1e;
  border-radius: 8px;
  color: white;
}
.button-headerMain-01 {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 126px;
  height: 36px;
  position: absolute;
  right: 18px;
  background: #1e1e1e;
  border-radius: 35px;
  color: white;
}
.div-headerMain-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  padding: 0 33px;
  height: 36px;
  position: absolute;
  right: 144px;
}
.img-header-logo {
  height: 25px;
}
.img-header-notification {
  margin-left: 12px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.img-header-arrowDown {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* -------------------------0.75 조정하기------------------------- */
.img-main-background {
  /* position: absolute; */
  /* top: -50px; */
  width: 1668px;
  max-width: 90%;
  animation: main-background-down 0.75s ease-out;
  animation-delay: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 10;
}
@keyframes main-background-down {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  50% {
    transform: translateY(5%);
  }
  70% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(-0.3%);
  }
  90% {
    transform: translateY(0.1%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.img-main-logo {
  position: absolute;
  top: 50%;
  animation: main-logo-up 0.5s ease-out;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  z-index: 30;
}
@keyframes main-logo-up {
  0% {
    top: 50%;
  }
  100% {
    top: calc(50% - 72px);
  }
}
.img-mainm-logo {
  position: absolute;
  top: calc(50% - 102px);

  z-index: 30;
}
.div-main-section {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: calc(100vh - 61.5px);
}
.div-main-section::-webkit-scrollbar {
  display: none;
}

.div-main-section-01 {
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5vw;
  /* padding: 0 0 0 100px; */
  align-items: center;
  position: relative;
}

.div-main-section-01-middle {
  position: absolute;
  top: 50%;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 39px;
  line-height: 45px;
  color: #ffffff;

  animation: fadein 0.5s ease-out;
  animation-delay: 1.75s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 30;

  word-break: keep-all;
  text-align: center;
}
.div-main-section-01-bottom {
  position: absolute;
  bottom: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  opacity: 0;
  z-index: 20;
}
.div-mainm-section-01-bottom {
  position: absolute;
  bottom: 60px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  z-index: 20;
}
.bounce-once {
  animation: bounce 1.5s ease;
  /* animation-delay: 0.5s; */
}
.bounce {
  animation: bounce 1.5s ease infinite;
  animation-delay: 2.75s;
  animation-fill-mode: forwards;
}
.bounceM {
  animation: bounce 1.5s ease infinite;
  animation-fill-mode: forwards;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(4px);
  }
  40% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.button-main-start {
  position: absolute;
  top: calc(50% + 90px);

  width: 189px;
  height: 43.5px;
  border-radius: 29px;

  animation: fadein 0.75s ease-out;
  animation-delay: 1.75s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 30;
}
/* .button-main-start-demo {
  position: absolute;
  top: calc(50% + 148px);

  width: 189px;
  height: 43.5px;
  border-radius: 29px;
  animation: fadein 0.75s ease-out;
  animation-delay: 1.75s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 30;
} */
.button-main-start-demo-form {
  position: absolute;
  top: calc(50% + 140px);

  width: 189px;
  height: 43.5px;
  border-radius: 29px;
  animation: fadein 0.75s ease-out;
  animation-delay: 1.75s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 30;
  color: white;
  text-decoration: underline;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.div-main-section-02 {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20vw;
  position: relative;
}
.div-main-section-02-top {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  padding-bottom: 200px;
}
.div-mainm-section-02-top {
  text-align: center;
}
.div-main-section-02-top-category {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  width: 228px;
  height: 46.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 12px;
  color: #f5f5f5;
  text-align: center;
  position: relative;
}
.div-mainm-section-02-top-category {
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 12px;
  color: #f5f5f5;
  text-align: center;
  position: relative;
}
.category-01 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  animation: change-category 8s linear;
  animation-iteration-count: infinite;
  opacity: 0;
}
.category-02 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  animation: change-category 8s linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  opacity: 0;
}
.category-03 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  animation: change-category 8s linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  opacity: 0;
}
.category-04 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  animation: change-category 8s linear;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes change-category {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.div-main-section-02-top-01 {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
}

.img-main-section-02-bottom {
  position: absolute;
  bottom: 0;
  height: 256.5px;
  width: 100%;
  object-fit: cover;
}
.img-main-section-02-bottom-blur {
  position: absolute;
  bottom: 0;
  height: 180px;
  width: 100%;
  object-fit: cover;
}
.div-mainm-section-02-bottom {
  width: 100%;
}
.div-mainm-section-02-chip-section {
  width: calc(50vw - 12px);
  height: 110px;
  background: #ffffff;
  /* box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transform: translateX(-50%);
}
.div-mainm-section-02-chip-content {
  text-align: center;
  width: 100%;
  word-break: keep-all;
}
.point-right {
  transform: translateX(15%);
}
.div-main-section-03-01 {
  width: 100%;
  height: 576px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  background: white;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
}
.div-main-section-03-02 {
  width: 100%;
  height: 689px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  background: white;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
}
.div-main-section-03-03 {
  width: 100%;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  background: #333333;
  color: white;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
}
.div-mainm-section-03-03 {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  background: #333333;
  color: white;
}
.div-main-section-03-02-bottom {
  width: 100%;
}
.div-main-section-03-02-chip-section {
  margin-top: 12px;
  margin-bottom: 12px;
  width: calc(20vw - 18px);
  height: calc(20vw - 18px);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transform: translateX(-50%);
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
}
.img-main-section-03-02-chip {
  height: 35%;
  margin-bottom: 12px;
}
.div-main-section-03-02-chip-content {
  text-align: center;
  width: 100%;
  word-break: keep-all;
}

.div-mainm-section-03-02-chip-section {
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(50vw - 12px);
  height: calc(50vw - 12px);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transform: translateX(-50%);
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
}
.img-mainm-section-03-02-chip {
  height: 35%;
  margin-bottom: 9px;
}
.div-mainm-section-03-02-chip-content {
  text-align: center;
  width: 100%;
  word-break: keep-all;
}

.div-main-section-04 {
  width: 100%;
  height: 635.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;

  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
  background-color: #f7f5f7;
}
.div-main-section-04-left {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
}
.div-main-section-04-left-sub {
  margin-top: 54px;
}
.div-main-section-04-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-main-section-05 {
  width: 100%;
  height: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;

  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
  background: #141414;
}
.div-main-section-05-right {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
  color: white;
  text-align: right;
}
.div-main-section-05-right-sub {
  margin-top: 54px;
  text-align: right;
}
.div-main-section-05-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-main-section-06 {
  width: 100%;
  height: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;

  font-weight: 400;
  font-size: 36px;
  line-height: 30px;
  background: #fffdff;
}
.div-main-section-last {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 21px;
  padding: 96px 0;
  color: white;
  text-align: center;
}
.button-main-section-last {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 189px;
  height: 43.5px;
  border-radius: 29px;
}
.div-main-footer-section {
  background: #1e1e1e;
  display: flex;
  flex-direction: column;
  padding: 27px 100px;
  color: white;
  text-align: center;
  position: relative;
}
.div-mainm-footer-section {
  background: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px;
  color: white;
  text-align: center;
  position: relative;
}
.div-main-footer-section-01 {
  display: flex;
  gap: 60px;
  color: #ff7a00;
  margin-bottom: 27px;
}
.div-mainm-footer-section-01 {
  display: flex;
  gap: 24px;
  color: #ff7a00;
  margin: 20px 0;
}
.div-main-footer-section-01-button {
  color: #ff7a00;
  cursor: pointer;
}

.div-main-footer-section-02 {
  margin-bottom: 15px;
  display: flex;
  gap: 30px;
  color: #cdcdcd;
}

.div-main-footer-section-02-text {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #cdcdcd;
}
.div-mainm-footer-section-02 {
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  color: #cdcdcd;
}
.img-main-footer-logo {
  height: 70px;
  position: absolute;
  right: 100px;
}
.img-mainm-footer-logo {
  height: 60px;
}
/* -------------------------------------------------- */

.div-manage-section {
  display: flex;
  width: 100%;
  height: calc(100vh - 61.5px);
  background-color: #f8f8f8;
}

.div-manage-section-01 {
  box-sizing: border-box;
  min-width: 78px;
  height: 100%;
  border-right: 2px solid #ececec;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  z-index: 1;
}
.div-manage-section-01-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-manage-logo-circle-checked {
  border: 1.5px solid #9f9f9f;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}
.img-manage-logo-circle {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  margin: 9px 0;
  border-radius: 100%;
  object-fit: cover;
}

.img-manage-logo-plus {
  width: 18px;
  height: 18px;
  margin: 9px 0;
}
.icon {
  width: 18px;
  height: 18px;
}
.icon:hover {
  cursor: pointer;
}

.iconM {
  width: 24px;
  height: 24px;
}
.iconM:hover {
  cursor: pointer;
}
.img-manage-share {
  width: 15px;
  height: 15px;
}

.div-manage-share-section {
  position: absolute;
  bottom: 6px;
  height: 76.5px;
  display: flex;
  justify-content: center;
  /* border-top: 1px solid #ececec; */
  padding-top: 12px;
  background-color: white;
  width: 228px;
  gap: 6px;
}
.div-share-button-section {
  display: flex;
  width: 228px;
  height: 33px;
  gap: 6px;
  position: absolute;
  bottom: 40px;
}
.button-manage-share {
  display: flex;
  align-items: center;
  justify-content: center;

  /* width: 228px; */
  width: 100%;
  height: 33px;

  border-radius: 8px;
  gap: 7.5px;
}
.button-manage-delete-recruit {
  position: absolute;
  bottom: 10.5px;
  text-decoration: underline;
  color: #9f9f9f;
}

.img-manage-back {
  width: 36px;
}
.div-manage-poster {
  width: 228px;
  height: 228px;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  /* border: 2px solid #ececec; */
  border-radius: 8px;
}

.img-manage-poster {
  width: 228px;
  height: 228px;
  object-fit: contain;
  cursor: pointer;
}
.div-manage-section-02 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 282px;
  border-right: 2px solid #ececec;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-manage-logo-square {
  width: 60px;
  height: 60px;

  border-radius: 8px;
  object-fit: cover;
}

.img-manage-pic {
  width: 54.8px;
  height: 54.8px;

  background: #d9d9d9;

  border: 2px solid #ececec;
  border-radius: 8px;
  object-fit: cover;
}
.img-manage-download {
  width: 18px;
  height: 18px;
}
.div-manage-section-02-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 18px;
  width: 282px;
}
.div-manage-section-02-01-01 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
}
.div-manage-section-02-01-01-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  width: 171px;
}
.pre-manage-section-02-01-01-01 {
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}
.div-manage-section-02-02 {
  width: 100%;
}
.div-manage-section-02-02-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 18px;
  gap: 7.5px;
  width: 100%;
}
.div-manage-section-02-02-01-01 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 7.5px;

  width: 100%;
  height: 153px;

  /* Black50 */

  background: #f8f8f8;
  border-radius: 8px;
}
.div-manage-section-02-02-01-01-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
  height: 100%;
}
.div-manage-section-02-02-01-01-01-line {
  display: flex;
  align-items: flex-start;
  gap: 9px;

  width: 100%;
  height: 18px;
  text-align: left;
}
.div-manage-section-02-02-01-01-01-left {
  width: 60px;
  height: 15px;
  color: #4c4c4c;
}
.div-manage-section-02-02-01-01-01-right {
  width: 123px;
  height: 15px;
  color: #000000;
}
.div-manage-section-02-02-02 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 18px 0;

  margin-top: 18px;
  width: 100%;
}

.div-manage-section-02-02-02-02 {
  display: flex;
  align-items: flex-start;
  gap: 9px;

  width: 100%;
  margin: 12px 0;

  cursor: pointer;
}
.div-manage-section-02-02-03 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 12px;
  padding: 0 18px;
}
/* .div-manage-section-02-02-03 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 18px 24px;
  gap: 10px;

  width: 376px;
  height: 92px;
} */
/* .button-manage-section-02-02-03 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  width: 328px;
  height: 44px;


  background: #1e1e1e;
  border-radius: 8px;
} */
.div-manage-section-02-03 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.div-manage-section-02-03-open {
  height: 100%;
}

.button-manage-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px 18px;
  gap: 7.5px;

  width: 100%;
  background: #f8f8f8;
  border: none;
  border-bottom: 2px solid #ececec;
}
.div-manage-close {
  display: flex;
  align-items: center;
  gap: 6px;

  height: 18px;
}
.button-manage-section-02-03-mozip {
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
  gap: 7.5px;
  width: 100%;
  height: 40.5px;
  background-color: white;
  border-bottom: 1px solid #ececec;
}
.button-manage-section-02-03-mozip:hover {
  background: #ececec;
}
.checked {
  background: #ececec;
}
.div-manage-section-02-03-mozip-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0px;
  width: 100%;
  border-bottom: 1px solid #ececec;
  /* max-height: 264px; */
  max-height: 138px;
  overflow: auto;
}

.button-manage-mozip-list-section {
  text-align: left;
  padding: 12px 9px;
  width: 264px;
  height: 39px;

  border-radius: 8px;
  cursor: pointer;

  margin: 1.5px 1.5px;
  background-color: white;

  display: flex;
  align-items: center;
}
.button-manage-mozip-list-section:hover {
  background-color: #ececec;
}
.button-manage-mozip-list-section-checked {
  text-align: left;
  padding: 12px 9px;
  width: 264px;
  height: 39px;

  background: rgba(255, 122, 0, 0.3);
  border-radius: 8px;
  cursor: pointer;

  margin: 1.5px 1.5px;
  display: flex;
  align-items: center;
}
.div-chip-mozip-list {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
  width: 42px;
  height: 15px;
  background-color: #4c4c4c;
  color: white;
}
.div-chip-mozip-listM {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
  width: 56px;
  height: 20px;
  background-color: #4c4c4c;
  color: white;
}
.div-manage-mozip-list {
  width: 100%;
  height: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.div-manage-mozip-listM {
  width: 100%;
  height: 20px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .div-manage-mozip-list-new {
  width: 277px;
  height: 20px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .div-manage-section-02-03-02 {
  box-sizing: border-box;
  width: 377px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px 24px;
  gap: 10px;
  border-top: 1px solid #ececec;
} */
/* .div-manage-section-02-03-02-01 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 116px;
  height: 26px;
} */
.button-manage-section-02-04 {
  width: 100%;
  background-color: white;
  border-top: 1px solid #ececec;
  padding: 12px 18px;
}
/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ----------------------------------------------- */
.div-manage-section-03 {
  background: #f8f8f8;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 100%;
}
.div-manage-section-03-01 {
  width: 300px;
  height: 100%;
}
.div-manage-section-03-01-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #ffffff;
  padding: 24px 36px 21px 36px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.div-manage-section-03-01-01-scroll {
  height: calc(100% - 106.5px);
  overflow-y: auto;
}
.div-manage-section-03-01-01-01 {
  display: flex;
  align-items: center;
  gap: 9px;
  width: 100%;
  height: 22.5px;
  margin-bottom: 27px;
  justify-content: space-between;
}
.div-manage-section-03-01-01-01-reset {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #b4b4b4;
  z-index: 100;
  padding: 3px 4px 3px 2px;
  border-radius: 8px;
  background-color: white;
  flex-shrink: 0;
}
.div-manage-section-03-01-01-01-reset:hover {
  filter: brightness(0.9);
}

.closeAnimation-toast {
  animation: fadeout 0.5s ease-out;
}
.openAnimation-toast {
  animation: fadein 0.1s ease-out;
}

.div-manage-section-03-01-01-015 {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 100%;
  height: 22.5px;
  margin-bottom: 27px;
}
.div-manage-section-03-01-01-02 {
  box-sizing: border-box;

  width: 100%;
  height: 228px;

  background: #f8f8f8;

  border: 2px solid #ececec;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}
.pre-manage-section-03-01-01-03 {
  width: 100%;
  margin-top: 21px;
  white-space: pre-wrap;
  word-break: break-all;
  /* overflow: auto; */
}

.div-manage-section-03-01-01-04 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 9px;

  margin-top: 21px;
  width: 100%;

  color: black;
  background: #f8f8f8;
  border-radius: 8px;
}
.div-manage-section-03-01-01-04-row {
  display: flex;
  gap: 9px;
  align-items: center;
}

.div-header-outsection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: transparent;
}
.div-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-manage-recruit-detail-section-01 {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 18px 30px;

  width: 645px;
  height: 425px;
  gap: 21px;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.div-manage-recruit-form-check-section-header {
  width: 400px;
  height: 40px;
  overflow: auto;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  position: relative;
  padding-top: 20px;
}
.div-manage-recruit-form-check-section {
  width: 400px;
  height: 620px;
  overflow: auto;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  position: relative;
  border: 2px solid white;
}
.div-manage-recruit-detail-section-01-01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textarea-manage-recruit-detail-section-01-02 {
  padding: 9px 12px;
  width: 100%;
  height: 100%;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-y: auto;
  resize: none;
}
.div-manage-recruit-detail-section-01-03 {
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 15px;
}
.button-manage-recruit-detail-section-01-03 {
  width: 75px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-manage-recruit-detail-section-01-03-black {
  width: 75px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-manage-section-03-01 {
  box-sizing: border-box;
  margin: 21px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;

  width: 100%;

  background: #ffffff;

  border: 1px solid #1e1e1e;
  border-radius: 8px;

  color: black;
}
.button-manage-section-03-01-half {
  box-sizing: border-box;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  width: 50%;
  border-radius: 8px;
}
.div-manage-section-03-02 {
  box-sizing: border-box;
  padding-left: 0;
  /* width: calc(100% - 300px - 54px); */
  width: calc(100% - 300px);
  overflow-x: hidden;
}
.div-manage-section-03-02-wide {
  box-sizing: border-box;
  padding-left: 0;
  /* width: calc(100% - 54px); */
  width: 100%;
  overflow-x: hidden;
}
.div-manage-section-03-02-01 {
  box-sizing: border-box;
  display: flex;
  width: calc(100vw - 696px - 18px);
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  /* position: relative; */
  padding: 24px 36px 21px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: scroll; */
}
.div-manage-section-03-02-01-wide {
  box-sizing: border-box;
  display: flex;
  width: calc(100vw - 696px - 18px + 280px);
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  /* position: relative; */
  padding: 24px 36px 21px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: scroll; */
}
.div-manage-section-03-02-01-02 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;
  border-top: 1px solid #ececec;
  height: 56px;
  padding-top: 12px;
}
.div-manage-section-03-02-01-02-count-section {
  height: 43.5px;
  gap: 45px;
  display: flex;
  flex-shrink: 0;
}
.div-manage-section-03-02-01-02-count {
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.button-manage-section-03-02-01-02-complete {
  width: 141.75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
/* .div-manage-section-03-02 {
  padding: 0 24px 24px 24px;
  background-color: pink;

  display: flex;
}
.div-manage-section-03-02-01 {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
} */
/* ------------------------------------------ */

/* ////////////////////////////////////////// */
.button-chip:hover {
  filter: brightness(90%);
}

.button-chip-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 4.5px 6px;
  border: none;
}

.chip-message-0 {
  background: #cdcdcd;
  border-radius: 8px;
  color: white;
}
.chip-message-1 {
  background: rgba(255, 222, 103, 0.3);
  border-radius: 8px;
  color: #ff7a00;
}
.chip-message-2 {
  background: rgba(0, 177, 167, 0.1);
  border-radius: 8px;
  color: #00dcdc;
}
.chip-message-3 {
  background: rgba(255, 61, 0, 0.2);
  border-radius: 8px;
  color: #ff3d00;
}

.button-chip-status-0 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36.8px;
  height: 15px;
  background: rgba(255, 222, 103, 0.3);
  border-radius: 8px;
  color: #ff7a00;
  border: none;
}

.button-chip-status-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36.8px;
  height: 15px;
  background: #ff7a00;
  border-radius: 8px;
  color: #ffffff;
  border: none;
}
.button-chip-status-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36.8px;
  height: 15px;
  background: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  border: none;
}
.div-chip-option {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  gap: 7.5px;
  border-radius: 8px;
}
.div-chip-optionM {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  border-radius: 10px;
}
.div-chip-option-12 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  gap: 7.5px;
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: center;
  width: 39px;
}
.div-chip-option-13 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  gap: 7.5px;
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

/* ---------------------------------club manager info------------------- */
.div-ClubManagerInfo-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  padding: 24px 36px;
}

.div-ClubManagerInfo-section-01 {
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
}

.div-ClubManagerInfo-section-02 {
  overflow: auto;
  max-height: calc(100vh - 132px - 157.5px + 27px);
}

.table-ClubManagerInfo {
  width: 100%;

  text-align: center;
  border-collapse: collapse;
}
.table-ClubManagerInfo thead {
  background: #f8f8f8;
  height: 39px;
  position: sticky;
  top: 0;
}
.table-ClubManagerInfo td {
  border-left: 1px solid #ececec;
  padding: 9px 0;
}

.button-ClubManagerInfo-edit {
  position: absolute;
  top: 126px;
  right: 123px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;

  position: absolute;
  width: 60px;
  height: 21px;

  background-color: white;
  border: 1px solid #1e1e1e;
  border-radius: 14px;
}
.button-ClubManagerInfo-add {
  position: absolute;
  top: 126px;
  right: 54px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;

  position: absolute;
  width: 60px;
  height: 21px;

  background-color: white;
  border: 1px solid #1e1e1e;
  border-radius: 14px;
}

.div-ClubManagerInfo-modal-section {
  padding: 18px 42px;
  background: #ffffff;
  border-radius: 8px;
  width: 300px;
  height: auto;
}
.div-ClubManagerInfo-modal-section-01 {
  text-align: center;
  position: relative;
}
.div-ClubManagerInfo-modal-section-02 {
  margin-top: 24px;
  display: flex;
  gap: 12px;
}
.img-ClubManagerInfo-modal-close {
  position: absolute;
  right: 0;
}
.button-ClubManagerInfo-modal-add {
  margin-top: 21px;
  margin-bottom: 4.5px;
  width: 100%;
  padding: 9px 12px;

  background: #1e1e1e;
  color: white;
  border-radius: 8px;
}
.button-ClubManagerInfo-modal-add:hover {
  background: #4c4c4c;
  color: white;
}
.button-ClubManagerInfo-modal-add-selected {
  margin-top: 21px;
  margin-bottom: 4.5px;
  width: 100%;
  padding: 9px 12px;

  background: #ff7a00;
  color: white;
  border-radius: 8px;
}
/* ---------------------my page----------------------- */
.div-mypage-section {
  width: 217.5px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 21px 18px;
}
.div-header-mypage {
  position: absolute;
  top: 30px;
  z-index: 400;
}
.img-mypage-profile {
  width: 39px;
  height: 39px;
  border-radius: 100%;
  border: 1.5px solid #9f9f9f;
}
.div-mypage-section-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
}
.div-mypage-section-top-left {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.div-mypage-section-middle {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
}
.div-mypage-section-middle-menu {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 10.5px;
}
.img-mypage-section-middle {
  width: 15px;
  height: 15px;
}
.div-mypage-section-bottom {
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
  color: #cdcdcd;
}
/* ---------------------my page----------------------- */
/* ---------------------my account----------------------- */
.div-myaccount-section {
  width: 300px;
  height: 249px;
  background: #ffffff;
  border-radius: 8px;
  padding: 21px 36px;
}

.div-myaccount-section-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.div-myaccount-section-middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.div-myaccount-section-middle-menu {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  gap: 10.5px;
  margin-top: 7.5px;
  margin-bottom: 12px;
}

.input-myaccount-section-middle-menu {
  width: 228px;
  height: 33px;
  padding: 9px 12px;
  border: none;
  background: #f8f8f8;
}
.button-myaccount-section-middle-menu {
  width: 90px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}

.div-myaccount-section-bottom {
  margin-top: 6px;
  border-top: 1px solid #ececec;
  padding: 12px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 24px;
}
.div-myaccount-section-bottom-text {
  /* text-decoration: underline; */
  color: #cdcdcd;
}
/* ---------------------my account----------------------- */
/* ---------------------update info----------------------- */
.div-updateInfo-section {
  width: 300px;
  height: 336.8px;
  background: #ffffff;
  border-radius: 8px;
  padding: 21px 36px;
}

.img-updateInfo-section-middle {
  width: 120px;
  height: 120px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.div-myaccount-section-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.div-updateInfo-section-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-updateInfo-section-middle-01 {
  margin-top: 25.5px;
}
.div-updateInfo-section-middle-02 {
  margin-top: 4.5px;
  margin-bottom: 21px;
  color: #808080;
}
.div-updateInfo-section-bottom {
  margin-top: 6px;
  border-top: 1px solid #ececec;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.button-updateInfo-section-bottom {
  width: 90px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
/* ---------------------update info----------------------- */
/* ---------------------notification----------------------- */
.div-notification-section {
  width: 354px;
  height: 342px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 21px 18px;
}

.div-header-notification {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 400;
}
.div-notification-section-top {
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
  color: #808080;
}
.div-notification-section-top .checked {
  color: #4c4c4c;
  background: none;
}

.div-notification-section-bottom {
  height: calc(100% - 21px - 12px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4c4c;
}
/* ---------------------notification----------------------- */

/* ---------------------headerM----------------------- */
.div-headerM-section {
  height: 48px;
  width: 100%;
  padding: 12px 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
}
.img-headerM {
  height: 20px;
}
.img-headerMainM {
  height: 24px;
}
/* ---------------------headerM----------------------- */
.H3M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.Sub1M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.Sub2M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.Sub3M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.Sub4M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.Body4M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.Body5M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.Body6M {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
/* ---------------------form intro----------------------- */
.div-form-mobile-section {
  background-color: white;
  display: flex;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}
.div-form-mobile-section::-webkit-scrollbar {
  display: none;
}
.div-form-mobile {
  background-color: white;
  width: 100%;
  max-width: 480px;
}
.div-form-mobile-check {
  background-color: white;
  width: 100%;
  height: 100%;
}
.div-form-section {
  padding: 0 20px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  background-color: white;
  flex-shrink: 0;
}
.div-form-section::-webkit-scrollbar {
  display: none;
}
.div-form-club-info {
  display: flex;
  align-items: center;
  margin-top: 48px;
  padding: 8px 0;
  gap: 12px;
}
.img-form-club-info {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}
.img-form-club-poster {
  width: 100%;
  height: 320px;
  object-fit: contain;
}
.div-form-poster {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
  background: #f8f8f8;
  border-radius: 8px;
}

.div-form-recruit-title-section {
  margin-top: 28px;
  display: flex;
  width: 100%;
  justify-content: left;
}
.div-form-recruit-title {
}
.div-form-recruit-detail-section {
  width: 100%;
  margin-top: 28px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 16px;
}
.div-form-recruit-detail-01 {
  margin-top: 22px;
  color: #4c4c4c;
}
.div-form-recruit-detail-02 {
  margin-top: 14px;
}
.div-form-recruit-content {
  margin-top: 28px;
  font-family: "Pretendard";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  height: 160px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: keep-all;

  position: relative;
  padding-bottom: 28px;
}
.div-form-recruit-content-hidden {
  position: absolute;
  top: 86px;
  width: 100%;
  height: 74px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 100;
}
.button-form-recruit-content {
  padding: 12px 16px;
  gap: 10px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  color: black;
}
.div-form-ad {
  margin: 28px 0;
  background: #d9d9d9;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #f8f8f8;
}
.div-form-apply-section {
  position: sticky;
  width: 100%;
  height: 44px;
  left: 0;
  bottom: 62px;
  padding: 12px 24px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.button-form-apply {
  width: 178px;
  height: 44px;
  background: #1e1e1e;
  border-radius: 22px;
  color: #ffffff;
}

.button-form-apply-check {
  position: sticky;
  width: 178px;
  height: 44px;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  padding: 12px 24px;
  background: #1e1e1e;
  border-radius: 22px;
  color: #ffffff;
  z-index: 200;
}

.div-form-adfit {
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 200;
}
/* ---------------------form intro----------------------- */

/* ---------------------form question----------------------- */
.div-form-kakao-modal-section {
  width: calc(100% - 40px);
  max-width: 340px;
  height: 210px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 0 20px;
  position: relative;
}
.img-form-kakao-modal-close {
  position: absolute;
  top: 9px;
  right: 12px;
  cursor: pointer;
}
.button-form-kakao-modal-yes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-top: 40px;
  width: 100%;
  color: white;
  background: #1e1e1e;
  border-radius: 8px;
}
.button-form-kakao-modal-no {
  margin-top: 8px;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  text-decoration-line: underline;

  color: #808080;
}
.div-form-question-background {
  width: 100%;
  max-width: 480px;
  height: calc(100% - 48px);
  background-color: #000000;
  background-color: white;
  padding: 12px 20px 0 20px;
  position: fixed;
  top: 48px;
  overflow: hidden;
}
.div-form-question-background-check {
  width: 400px;
  height: 615px;
  background-color: #000000;
  background-color: white;
  padding: 12px 20px 20px;
  overflow: hidden;
}
.div-form-question-final-section {
  position: absolute;
  bottom: 0;
  text-align: center;
}
.button-form-question-final {
  width: 208px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-top: 12px;
  /* margin-bottom: 8px; */
  margin-bottom: 24px;

  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
}
.button-form-question-final-time {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-top: 12px;
  margin-bottom: 24px;

  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
}
.div-form-question-section {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 640px;
  /* border-radius: 12px; */
  background-color: white;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.div-form-question-section::-webkit-scrollbar {
  display: none;
}
.div-form-question-contents-section {
  flex: 500 500;
  min-height: 500px;
  width: 100%;
}
.div-form-question-section-01 {
  /* padding-top: 24px; */
  display: flex;
  justify-content: center;
}
.img-form-question-section-01 {
  width: 64px;
  height: 64px;
  /* background: #cdcdcd; */
  border-radius: 100%;
  object-fit: cover;
  /* border: 2px solid #ececec; */
}
.div-form-question-section-02 {
  margin-top: 32px;
  text-align: center;
}
.div-form-question-section-03 {
  margin-top: 60px;
  margin-top: 32px;
  display: flex;
  gap: 16px;
}
.div-form-question-section-03-01 {
  width: 50%;
}
.div-form-question-default-q {
  margin-bottom: 8px;
}
.div-form-question-section-03-a {
  display: flex;
  gap: 4px;
}

.input-form-question-section {
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.button-form-question-section-03-a-selected {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
}

.button-form-question-section-03-a {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 0.75px solid #1e1e1e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
}
.div-form-question-section-04 {
  margin-top: 20px;
  width: 100%;
}
.div-form-question-button-section-check {
  width: calc(100% - 32px);
  display: flex;
  gap: 8px;
  flex: 45 45;
  min-height: 45px;
}
.div-form-question-button-section {
  /* width: calc(100% - 32px);
  display: flex;
  gap: 8px;
  flex: 45 45;
  min-height: 45px;
  background-color: gold; */

  position: sticky;
  width: calc(100%);
  display: flex;
  justify-content: center;
  min-height: 45px;
  left: 50%;
  flex: 45 45;
  /* bottom: 24px; */
  bottom: 66px;
  /* transform: translateX(-50%); */
  padding: 12px 24px;
  border-radius: 22px;
  color: #ffffff;
  z-index: 200;
}
.button-form-question-pre {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 0.75px solid #1e1e1e;
  width: 50%;
  height: 44px;
  border-radius: 22px;
  z-index: 100;
}
.button-form-question-next {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  width: 50%;
  height: 44px;
  border-radius: 22px;
  z-index: 100;
}
.button-form-question-next-last {
  background-color: #ff7a00;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  width: 50%;
  height: 44px;
  border-radius: 22px;
  z-index: 100;
}
.div-form-question-progressbar-section {
  /* padding-top: 24px; */
  padding-top: 6px;
}
.div-form-question-progressbar {
  width: 100%;
  height: 8px;
  background: #f8f8f8;
  border-radius: 4px;
  position: relative;
}
.div-form-question-progressbar-black {
  width: 100px;
  height: 8px;
  background: #1e1e1e;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
/* ---------------------form question----------------------- */

/* ---------------------question----------------------- */
.div-question-num {
  margin-top: 24px;
  /* margin-top: 69px; */
  display: flex;
  align-items: center;
  gap: 7px;
}
.div-question-keyword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  background: rgba(255, 122, 0, 0.3);
  border-radius: 12px;
  color: #ff7a00;
}
.pre-question-title {
  margin-top: 16px;
  word-break: break-all;
  white-space: pre-wrap;
  max-height: 90px;
  overflow-y: hidden;
  padding-right: 20px;
}
.pre-showquestion-title {
  margin-top: 12px;
  word-break: break-all;
  white-space: pre-wrap;
  max-height: 53px;
  overflow-y: hidden;
  padding-right: 20px;
}
.button-question-title-show-whole {
  margin-top: 9px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: black;
  color: white;
  border-radius: 8px;
  padding: 3px 8px;
}
.div-question-whole-question-section {
  width: 85%;
  max-width: 408px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border: 1px solid #cdcdcd;
  z-index: 1000;
  border-radius: 8px;
  padding: 12px 16px;
}
.pre-question-whole-question {
  word-break: break-all;
  white-space: pre-wrap;
  margin-top: 12px;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.input-question-07-section {
  margin-top: 62px;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.textarea-question-01-section {
  margin-top: 62px;
  width: 100%;
  padding: 12px 16px;
  height: 124px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  resize: none;
}
.div-question-09-section-top {
  margin-top: 12px;
}
.div-question-09-section-01 {
  margin-top: 62px;
  overflow: auto;
}
.div-question-09-section-02 {
  margin-top: 62px;
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}
.div-question-09-section-03 {
  margin-top: 62px;
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}
.button-question-09-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  margin-bottom: 8px;
}
.button-question-09-02 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.button-question-09-03 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.div-question-10-section {
  margin-top: 80px;
  padding: 0 20px;
}
.div-question-12-sub {
  margin-top: 12px;
}
.div-question-12-section {
  margin-top: 30px;
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}
.button-question-12 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 12px 16px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.div-question-13-section {
  margin-top: 62px;
}
.div-question-05-dropdown {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background: #ffffff;

  border-radius: 8px;
  z-index: 100;
}

.div-question-05-sub {
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 8px;
}
.button-question-05 {
  width: 100%;
  height: 44px;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
  border-bottom: 1px solid #ececec;
}
.button-question-05:hover {
  color: #ff7a00;
}
.div-question-13-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  position: relative;
  z-index: 300;
}
.div-question-13-dropdown-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  /*  */
  border: none;
}

.div-question-13-dropdown-scroll {
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
  z-index: 100;
}
.button-question-13 {
  width: 100%;
  height: 44px;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
}
/* .button-question-13:hover {
  background: #ececec;
} */
.div-question-15-section {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.button-question-15 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 12px 16px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}

.button-question-19 {
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 7.5px;

  width: 100%;
  height: 44px;
  color: black;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.button-question-19:hover {
  cursor: pointer;
}
.form-question-18 {
  margin-top: 62px;
  display: flex;
  justify-content: center;
}
.form-question-19 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-question-19-fileName {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 12px;
  color: #ff7a00;
  cursor: pointer;
}
.form-question-19-fileName > img {
  width: 24px;
  height: 24px;
  filter: invert(50%) sepia(82%) saturate(2596%) hue-rotate(2deg)
    brightness(103%) contrast(106%);
}
.form-question-19 input[type="file"] {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.form-question-19 #thumb {
  margin-top: 12px;
  width: 100px;
}
.react-ios-time-picker-popup-overlay {
  position: fixed;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
}
/* ---------------------question----------------------- */

/* ---------------------form making----------------------- */
.div-formmaking-section {
  width: 725.25px;
  height: 545.25px;
  background-color: white;
  border-radius: 0 0 8px 8px;
  border: 1px solid #cdcdcd;
  border-top: none;
}
.div-formmaking-header-section {
  width: 725.25px;
  height: 64.5px;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 8px 8px 0 0;
  border: 1px solid #cdcdcd;
  border-bottom: none;
}
.div-formmaking-header-section-02 {
  width: fit-content;
  gap: 54px;
  display: flex;
  align-items: center;
}
.div-formmaking-leftright-section {
  width: 100%;
  height: 100%;
  position: relative;
}
.div-formmaking-left-section {
  width: 404px;
  height: 100%;
  background: #f8f8f8;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 36px;
  border-radius: 0 0 0 8px;
  position: absolute;
  left: 0;
}
.div-formmaking-left-slider-section {
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  text-align: center;
}
.div-formmaking-left-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-formmaking-right-section {
  width: 321px;
  height: 100%;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  position: absolute;
  right: 0;
}
.div-formmaking-right-section-01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  margin-bottom: 15px;
}
.div-formmaking-right-section-02 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}
.div-formmaking-right-section-02-01 {
  display: flex;
  gap: 12px;
}
.div-formmaking-right-section-02-sub {
  color: #9f9f9f;
}
.div-formmaking-right-section-02-02 {
  margin-top: 12px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
.div-formmaking-chip-question {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 9px;
  background: #1e1e1e;
  border-radius: 14px;
  color: white;
  gap: 9px;
}
.img-formmaking-chip-question-close {
  cursor: pointer;
  width: 15px;
  filter: invert(100%);
}
.div-formmaking-right-section-03 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.div-formmaking-right-section-03-01 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.div-formmaking-chip-recommend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;

  background: #ff7a00;
  border-radius: 10px;
  color: white;
}
.div-formmaking-right-section-03-02 {
  margin-top: 6px;
  color: #9f9f9f;
}

.div-formmaking-right-section-03-03 {
  margin-top: 12px;
  padding: 12px;
  max-height: 192px;
  overflow-y: scroll;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.div-formmaking-right-section-03-03-question {
  display: flex;
  align-items: center;
  gap: 6px;
}
.div-formmaking-chip-keyword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  background: rgba(255, 122, 0, 0.3);
  border-radius: 12px;
  color: #ff7a00;
  margin-left: 3px;
  flex-shrink: 0;
}
.div-formmaking-chip-questiontext {
  overflow: hidden;
  display: inline;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70%;
}
.div-formmaking-right-section-button {
  display: flex;
  gap: 9px;
  width: calc(100% - 60px);
  position: absolute;
  bottom: 24px;
}
.button-formmaking-right-section-pre {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;

  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.button-formmaking-right-section-next {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;

  background: #1e1e1e;
  color: white;
  border-radius: 8px;
}
/* ---------------------form making----------------------- */

/* ---------------------selected question----------------------- */
.div-selected-list-section {
  width: 100%;
  height: 270px;
  overflow-y: auto;
  position: relative;
}
.div-selected-header-section {
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;

  position: absolute;
  top: 115.5px;
}
.div-selected-header-section-01 {
  display: flex;
  align-items: center;
  gap: 3px;
}
.div-selected-header-section-01-num {
  color: #ff7a00;
}
.button-selected-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 9px;
  height: 21px;
  gap: 6px;
  background: #ffffff;
  color: black;
  border: 1px solid #1e1e1e;
  border-radius: 14px;
}
.div-selected-section {
  width: calc(100% - 60px);
  position: relative;
  position: absolute;
  top: 145.5px;
}
.div-selected-question-modal {
  position: absolute;
  top: 24px;
  right: 9px;
  width: 69px;
  height: 60px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9px;
  z-index: 1000;
}
.div-selected-question-modal-row {
  display: flex;
  gap: 6px;
}
.div-selected-question-section {
  width: 100%;
  height: 33px;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 6px;
  gap: 12px;
  position: relative;
}
.div-selected-question-section:hover {
  background-color: #ececec;
  z-index: 999;
  cursor: pointer;
}
.div-selected-question-section-text {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.div-selected-trash {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.img-selected-trash {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-selected-etc {
  position: absolute;
  right: 12px;
  width: 15px;
}
.img-selected-etc:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.div-selected-hidden {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 100;
}

.div-selected-question-type-section {
  width: 100%;
  margin-bottom: 43.5px;
  height: fit-content;
  z-index: 1000;
}
.div-selected-question-dropdown-box {
  width: 100%;
  height: 33px;
  background: transparent;

  padding: 12px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
}
.button-selected-question-dropdown {
  width: 100%;
  height: 33px;
  text-align: left;
  padding: 12px 9px;
  border-radius: 8px;
  background-color: white;
}
.button-selected-question-dropdown:hover {
  background: #ececec;
}

.div-selected-question-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  position: relative;
  z-index: 300;
  margin-top: 11.25px;
  /*  */
  max-height: 165px;
  overflow-y: auto;
}

.div-selected-question-dropdown-scroll {
  max-height: 165px;
  overflow-y: auto;
  z-index: 300;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* ---------------------selected question----------------------- */

/* ---------------------edit question----------------------- */
.div-edit-background {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 0 8px;
}
.div-edit-mode-section {
  width: 177px;
  height: 39px;

  background: #e2e2e2;
  border-radius: 12px;
  display: flex;
  padding: 3px;
}
.button-edit-mode {
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 8px;
}
.button-edit-mode-selected {
  width: 100%;
  height: 100%;
  background-color: white;
  color: #ff7a00;
  border-radius: 8px;
}

.div-edit-section {
  margin-top: 36px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 15px 48px;
  position: relative;
}
.div-edit-type-01-section {
  height: 248px;

  overflow: auto;
}
.div-edit-trash-section {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 11px 48px;
  height: 55px;
  /* background: #000000;
  opacity: 0.5; */
  border-radius: 0 0 8px 8px;
  color: white;
}
.div-edit-trash-close {
  position: inherit;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 33px;
  height: 33px;
  background: #000000;
  opacity: 0.5;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.div-edit-trash-open {
  position: inherit;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  width: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;

  height: 33px;
  background: #000000;
  opacity: 0.5;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.div-edit-trash-open2 {
  position: inherit;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  width: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;

  height: 33px;
  background: #000000;
  opacity: 0.5;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.img-edit-trash {
  filter: invert(100%);
  transform: translateX(0.5px);
}
.div-edit-section-num {
  margin-bottom: 13.5px;
  display: flex;
  gap: 5.25px;
  align-items: center;
}
.div-edit-section-keyword {
  padding: 9px 6px;
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  word-break: break-all;
  height: 24px;
  text-align: left;
}
.div-show-section-keyword {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75px 6px;
  background: rgba(255, 122, 0, 0.3);
  border-radius: 12px;
  color: #ff7a00;
}
.textarea-edit-section-question {
  margin-bottom: 10.5px;
  padding: 9px 12px;
  width: 100%;
  resize: none;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  word-break: break-all;
  height: 45px;
}
.div-edit-section-question {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
  height: 45px;
  height: 65px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10.5px;
  word-break: break-all;
}
.div-edit-section-checkbox {
  /* position: absolute;
  top: 100px; */
  display: flex;
  gap: 9px;
}

.div-edit-type-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33px;
  padding: 0 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 6px;
}
.input-edit-type-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.input-edit-type-01::placeholder {
  color: black;
}

.div-edit-type-02-section {
  width: 100%;
  display: grid;
  gap: 6px;
  grid-template-columns: 1fr 1fr;
}
.div-edit-type-02 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0 6px;
  width: 105px;
  height: 33px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.input-edit-type-02 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.input-edit-type-02::placeholder {
  color: black;
}
.div-edit-type-03-section {
  width: 100%;
  display: grid;
  gap: 6px;
  grid-template-columns: 1fr 1fr 1fr;
}
.div-edit-type-09-multi-info {
  width: 100%;
  text-align: left;
  margin-bottom: 10.5px;
}

.div-edit-type-03 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0 6px;
  width: 66px;
  height: 33px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
}
.input-edit-type-03 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.input-edit-type-03::placeholder {
  color: black;
}
.div-edit-type-04 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 200px;
  overflow-y: auto;
  margin-top: 21px;
}
.button-edit-type-04 {
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: 195px;
  height: 30px;
}

.div-edit-type-04-option {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
}
.img-edit-type-04-delete {
  position: absolute;
  right: 0;
  /* transform: translateX(30px); */
}
.input-edit-type-04 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  width: 195px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.input-edit-type-04::placeholder {
  color: black;
}

.input-edit-type-05 {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  width: 100%;
  height: 33px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}

.textarea-edit-type-06 {
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 12px;
  width: 100%;
  height: 93px;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  resize: none;
}

.div-edit-dropdown-section {
}
.div-question-13-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  z-index: 100;
}
.div-question-13-dropdown-formtime {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  z-index: 100;

  width: calc(100% - 24px);
}
.div-edit-dropdown-box {
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
}
.div-edit-dropdown-scroll {
  height: 165px;
  overflow-y: scroll;
}
.button-edit-dropdown {
  width: 100%;
  height: 33px;
  text-align: left;
  padding: 9px 12px;
}
.button-edit-dropdown:hover {
  background: #ececec;
}
.div-edit-type-10-section {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-top: 45px;
}
.div-edit-type-20 {
  margin-top: 45px;
  color: #9f9f9f;
  text-align: center;
}
/* ---------------------edit question----------------------- */

/* main {
  overflow-x: hidden;
  display: flex;
}

h1 {
  margin: 0;
  padding: 0;
}

section {
  min-width: 50vw;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4ch;
}

section:nth-child(even) {
  background-color: teal;
  color: white;
} */

/* ---------------------coming soon----------------------- */
.div-comingsoon-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  padding: 24px 36px;
}
.img-comingsoon {
  width: 390px;
}
.div-comingsoon-section-title {
  margin-top: 33px;
}
.div-comingsoon-section-sub {
  margin-top: 9px;
}

.div-comingsoonM-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 64px 56px;
}
.img-comingsoonM {
  width: 100%;
}
.div-comingsoon-section-title {
  margin-top: 33px;
}
.div-comingsoon-section-sub {
  margin-top: 9px;
}
/* ---------------------coming soon----------------------- */

/* ---------------------skeleton----------------------- */
.skeleton {
  animation: skeleton 1s infinite;
  background-color: #f8f8f8;
}
.skeleton-manage-logo-circle {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  margin: 9px 0;
  border-radius: 100%;
  background-color: #f8f8f8;
  animation: skeleton 1s infinite;
}
.skeleton-manage-logo-square {
  width: 60px;
  height: 60px;

  border-radius: 8px;
  background-color: #f8f8f8;
  animation: skeleton 1s infinite;
}
.skeleton-manage-section-02-01-01-01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  width: 171px;
  height: 60px;
  background-color: #f8f8f8;
  border-radius: 8px;
  animation: skeleton 1s infinite;
  /* animation-delay: 0.1s; */
}
.skeleton-manage-poster {
  width: 228px;
  height: 228px;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  /* border: 2px solid #ececec; */
  border-radius: 8px;
  animation: skeleton 1s infinite;
}
.skeleton-manage-section-03-01-01-03 {
  width: 100%;
  height: 35px;
  background-color: #f8f8f8;
  margin-top: 21px;
  border-radius: 8px;
  animation: skeleton 1s infinite;
  /* animation-delay: 0.1s; */
}
.skeleton-manage-section-03-01-01-04 {
  margin-top: 21px;
  width: 100%;
  height: 120px;
  background: #f8f8f8;
  border-radius: 8px;
  animation: skeleton 1s infinite;
  /* animation-delay: 0.2s; */
}
.skeleton-div-recruit01-selection {
  background-color: #f8f8f8;
  width: 270px;
  height: 49.5px;
  border-radius: 8px;
  cursor: pointer;
  animation: skeleton 1s infinite;
}

@keyframes skeleton {
  0% {
  }
  50% {
    /* filter: brightness(95%); */
    background-color: #ececec;
  }
  100% {
  }
}

.skeleton-form-intro {
  border-radius: 8px;
  background-color: #f8f8f8;
  animation: skeleton 1s infinite;
}

/* ---------------------skeleton----------------------- */
.div-terms-section {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: calc(100vh - 61.5px);
}
.div-terms-section::-webkit-scrollbar {
  display: none;
}
.div-terms-text {
  padding: 50px 100px;
  font-family: "Pretendard";
  line-height: 1.5em;
}
.div-terms-section h2 {
  font-family: "Pretendard";
  margin-bottom: 30px;
}
.div-terms-section h3 {
  font-family: "Pretendard";
  margin-top: 20px;
}

.div-timetable-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 36px 24px 36px;
  gap: 6px;
  width: 100%;
  height: 22.5px;
}
.div-timetable-section-top {
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  height: 90px;
  padding: 0 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 9px;
}
.div-timetable-section-01 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
}
.div-timetable-no-time {
  margin-top: 21px;
  width: 100%;
  height: calc(100vh - 380px);
  background-color: #f8f8f8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cdcdcd;
  text-align: center;
}
.div-timetable-section-01-01-dropdown-box {
  width: 100%;
  height: 33px;
  background: transparent;

  padding: 12px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
}
.button-timetable-section-01-01-dropdown {
  width: 100%;
  height: 33px;
  text-align: left;
  padding: 12px 9px;
  border-radius: 8px;
}
.button-timetable-section-01-01-dropdown:hover {
  background: #ececec;
}

.div-timetable-section-01-02-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background: #ffffff;

  border: 1px solid #cdcdcd;
  border-radius: 8px;
  position: relative;
  z-index: 300;
}
.div-timetable-section-01-02-dropdown-box {
  width: 75px;
  height: 33px;
  background: transparent;

  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
}
.div-timetable-section-01-02-dropdown-scroll {
  max-height: 165px;
  overflow-y: scroll;
  z-index: 300;
}
.button-timetable-section-01-02-dropdown {
  width: 100%;
  height: 33px;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
}
.button-timetable-section-01-02-dropdown:hover {
  background: #ececec;
}

.div-timetable-section-02 {
  margin: 10.5px 0;
  display: flex;
  gap: 4.5px;
  flex-wrap: wrap;
  padding-bottom: 18px;
  border-bottom: 1px solid #ececec;
}
.div-timetable-section-02-chip {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 9px;
  width: fit-content;
  height: fit-content;
  color: white;
  background: #6a6a6a;
  border-radius: 17px;
}
.img-timetable-section-02 {
  width: 12px;
  filter: invert(100%);
}
.div-timetable-section-03-wrap {
  height: calc(100vh - 410px);
  width: 100%;
  overflow: scroll;
  display: flex;
  gap: 18px;
  position: relative;
}

.div-timetable-section-03-top {
  margin-top: 22.5px;
  display: flex;
  gap: 18px;
  width: 100%;
}
.div-timetable-section-03-01 {
  width: 147.75px;
  height: 27px;
  padding: 0 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
  border-radius: 8px;
  margin-top: 22.5px;
  margin-bottom: 6.75px;
}
.div-timetable-section-03-02 {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff7a00;
  color: white;
  border-radius: 100%;
  margin-right: 24px;
}
.div-timetable-section-03-03 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.div-timetable-section-03-03-row {
  width: 147.75px;
  height: 27px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 8px;
  cursor: pointer;
}

.div-timetable-section-03-03-row-modal {
  position: absolute;
  top: 33px;
  left: 0;
  width: 150px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  z-index: 200;
  color: black;
}
.div-timetable-section-03-03-row-modal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-timetable-modal-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 15px;
  border-radius: 10px;
}
/* ////////////////////////////////////////// */
.div-message-modal {
  width: 725.25px;
  height: 519px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 0;
  position: relative;
}
.img-message-section-close {
  position: absolute;
  top: 21px;
  right: 36px;
}
.div-message-section-01 {
  margin-top: 30px;
  display: flex;
}
.div-message-section-01-left {
  width: 60px;
  padding-top: 6px;
  text-align: left;
}
.div-message-section-01-right {
  width: 405px;
  height: 58.5px;
  overflow-y: scroll;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  display: flex;
  gap: 4.5px;
  flex-wrap: wrap;
  padding: 6px 9px;
}
.div-message-section-01-right-count {
  width: 465px;
  text-align: right;
  padding-right: 6px;
  margin-top: 6px;
  color: #808080;
}
.div-message-section-01-right-chip {
  /* width: 49.5px; */
  padding: 0 6px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #808080;
  border-radius: 14px;
  background: #f8f8f8;
}
.div-message-section-01-right-chip-pass {
  /* width: 49.5px; */
  padding: 0 6px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00dcdc;
  border-radius: 14px;
  background: rgba(0, 177, 167, 0.1);
}
.div-message-section-02 {
  display: flex;
}
.div-message-section-02-right-chip-section {
  width: 465px;
  padding-left: 66px;
  margin-top: 15px;
  margin-bottom: 9px;
  display: flex;
  gap: 6px;
}

.div-message-section-02-right-chip {
  padding: 3px 9px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.textarea-message-section-02-right {
  width: 405px;
  height: 240px;
  overflow-y: scroll;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  resize: none;
  padding: 9px 12px;
}
.textarea-message-section-02-right-final {
  width: 405px;
  height: 114px;
  overflow-y: scroll;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  resize: none;
  padding: 9px 12px;
}
.button-message-bottom {
  width: 120px;
  height: 33px;
  background: #1e1e1e;
  border-radius: 8px;
  color: white;
  position: absolute;
  bottom: 18px;
  right: 36px;
}
.button-message-bottom-left {
  width: 120px;
  height: 33px;
  border-radius: 8px;
  position: absolute;
  bottom: 18px;
  right: 162px;
}

/* ////////////////////////////////// */
.div-timetable-modal {
  width: 726.75px;
  height: 715.5px;
  background: #ffffff;
  border-radius: 8px;
  padding: 21px 36px;
  position: relative;
  text-align: center;
}
.div-timetable-modal-wrap {
  margin-top: 39px;
  height: 537.75px;
  width: 100%;

  overflow: scroll;
  display: flex;
  gap: 18px;
  position: relative;
}

/* ------------ form time ------------- */
.div-form-time-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 50px;
}
.div-form-time-title-medi {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 16px;
  gap: 8px;
}

.div-form-time-button-section {
  position: absolute;
  top: 180px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 50%;
  width: calc(100% - 28px);
  overflow: auto;
}
.button-form-time {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #ff7a00;
  border: 0.75px solid #ff7a00;
  width: 100%;
  height: 44px;
}
.button-form-time:disabled {
  background-color: #ececec;
  color: #ffffff;
  border: 0.75px solid #ececec;
  cursor: default;
}
.button-form-time-select {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 9px 12px;
  border-radius: 8px;
  width: calc(100% - 32px);
  height: 44px;
  position: absolute;
  left: 16px;
  bottom: 24px;
  border-radius: 22px;
}

.div-form-time-completed-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.div-form-time-completed-section {
  margin-top: 64px;
  background: #f8f8f8;
  border-radius: 8px;
  text-align: center;
  padding: 22px 0;
  width: 100%;
}
.div-form-time-completed-section-t {
  margin-top: 64px;
  background: #f8f8f8;
  border-radius: 8px;
  text-align: center;
  padding: 21px 24px;
  width: 100%;
}
.div-form-time-completed-section-01 {
  text-align: center;
  color: #ff7a00;
  margin-bottom: 12px;
}
.div-form-time-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 12px 12px;
  gap: 20px;

  width: 268px;
  height: 182px;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.button-form-time-modal {
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
}
.div-form-time-notice-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 12px 12px 12px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 999;
}

.button-form-time-notice {
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 8px;
  border: 0.75px solid #1e1e1e;
  padding: 4px 8px;
}
/* //////////////////// show time table  */
.div-show-timetable-section {
  width: 725.25px;
  height: 545.25px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 36px;
  position: relative;
}
.div-show-timetablem-section {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 36px;
  position: relative;
}
.div-show-timetable-scroll-section {
  height: 100%;
  display: flex;
  gap: 18px;
  width: 100%;
  padding: 30px 0 6px 0;
  overflow-x: auto;
}
.div-show-timetable-section-01 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  flex-shrink: 0;
  height: 100%;
}

.div-show-timetable-section-01-date {
  width: 122px;
  height: 27px;
  padding: 6px 9px;
  text-align: center;
  background: #1e1e1e;
  border-radius: 8px;
  color: white;
}
.div-show-timetablem-section-01-date {
  width: 160px;
  height: 36px;
  padding: 8px 12px;
  text-align: center;
  background: #1e1e1e;
  border-radius: 8px;
  color: white;
}
.div-show-timetable-section-01-table {
  overflow-y: scroll;
  display: flex;
  gap: 6px;
  flex-direction: column;
}
.div-show-timetable-section-01-time-name {
  display: flex;
  gap: 6px;
  margin-right: 6px;
}
.div-show-timetable-section-01-time {
  background: #f8f8f8;
  border-radius: 8px;
  width: 122px;
  height: 27px;
  padding: 6px 9px;
  text-align: center;
}
.div-show-timetablem-section-01-time {
  background: #f8f8f8;
  border-radius: 8px;
  width: 160px;
  height: 36px;
  padding: 8px 12px;
  text-align: center;
}
.div-show-timetable-section-01-name-section {
  background: #f8f8f8;
  border-radius: 8px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-show-timetablem-section-01-name-section {
  background: #f8f8f8;
  border-radius: 8px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-show-timetable-section-01-name {
  padding: 0 6px;
}
.div-show-timetablem-section-01-name {
  padding: 0 8px;
}
/* mainM */
.div-mainm-menu-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.div-mainm-menu-background-left {
  height: 100vh;
}
.div-mainm-menu-section {
  width: 296px;
  height: 100%;
  background: #ffffff;

  position: absolute;
  right: 0;
  z-index: 100;
  padding: 0 20px;
}

.img-mainm-background {
  position: absolute;
  bottom: -5vh;
  width: 100%;
  z-index: 10;
}
.div-mainm-menu-section-top {
  height: 48px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.spread {
  animation: spread 0.3s ease-out;
}

.fold {
  animation: fold 0.3s ease-out;
  transform: rotate(180deg);
}

@keyframes spread {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes fold {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}
.openAnimation1 {
  animation: openMenu 0.3s ease-out;
}
.closeAnimation1 {
  animation: closeMenu 0.3s ease-out;
}

.openAnimation2 {
  animation: fadein 0.3s ease-out;
}
.closeAnimation2 {
  animation: fadeout 0.3s ease-out;
}

.openAnimation3 {
  animation: openMenu3 0.3s ease-out;
}
.closeAnimation3 {
  animation: closeMenu3 0.3s ease-out;
}

@keyframes openMenu {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes closeMenu {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes openMenu3 {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes closeMenu3 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.button-mainm-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 35px;
  margin-top: 12px;
}
.div-mainm-menu-section-01 {
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.div-mainm-section {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: calc(100vh - 48px);
  height: 100%;
  background-color: black;
}
.div-mainm-section::-webkit-scrollbar {
  display: none;
}

.div-mainm-section-01 {
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.div-main-section-01-middle {
  position: absolute;
  top: 50%;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 39px;
  line-height: 45px;
  color: #ffffff;

  animation: fadein 0.5s ease-out;
  animation-delay: 1.75s;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 30;

  word-break: keep-all;
  text-align: center;
}
.div-mainm-section-01-middle {
  position: absolute;
  top: calc(50% - 30px);

  color: #ffffff;

  z-index: 30;

  word-break: keep-all;
  text-align: center;
}
.button-mainm-community {
  position: absolute;
  top: calc(50% + 52.5px);

  width: 180px;
  border-radius: 29px;

  z-index: 30;
}
.button-mainm-start {
  position: absolute;
  /* top: calc(50% + 105px); */
  top: calc(50% + 75px);
  width: 180px;
  border-radius: 29px;

  z-index: 30;
}

.button-mainm-start-demo-form {
  position: absolute;
  /* top: calc(50% + 152px); */
  top: calc(50% + 122px);

  width: 154px;
  height: 44px;
  border-radius: 29px;

  z-index: 30;
  color: white;
  text-decoration: underline;
}
.div-mainm-section-02 {
  width: 100%;
  height: 436px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
}
.div-mainm-section-03 {
  width: 100%;
  height: 394px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.div-mainm-section-04 {
  width: 100%;
  height: 657px;
  /* background: #f7f5f7; */
  background: #f5f3f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 24px;
  position: relative;
}
.img-mainm-section-04 {
  width: 277px;
  height: 327px;
  background-color: black;
}
.div-mainm-section-05 {
  width: 100%;
  height: 561px;
  background: #141414;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 24px;
  position: relative;
}
.img-mainm-section-05 {
  width: 280px;
}
.div-mainm-section-06 {
  width: 100%;
  height: 657px;
  /* background: #fffdff; */
  background: #fdfbfd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 24px;
  position: relative;
}
.div-mainm-section-07 {
  width: 100%;
  height: 657px;
  /* background: #f7f5f7; */
  background: #f5f3f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 24px;
  position: relative;
}
.div-mainm-section-title-sub {
  width: 280px;
}
.div-mainm-section-04-title {
  margin-bottom: 24px;
}
.div-mainm-section-08 {
  width: 100%;
  height: 268px;
  background: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  gap: 24px;
  position: relative;
  text-align: center;
}
.button-mainm-section-08 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 44px;
  border-radius: 29px;
}
.div-guide-dim {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1230;
}
.div-guide {
  position: absolute;
  background: #ff7a00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  padding: 2px 3px;
  white-space: pre;
}
.div-guide-wrapper {
  position: relative;
}
.div-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.guide-current-index {
  background-color: #ff7a00;
  color: white;
  outline: 2px solid rgba(255, 122, 0, 0.3);
}
.guide-other-index {
  background-color: #4c4c4c;
  color: #9f9f9f;
}

/* ---------------------ShowData----------------------- */
.div-macle-background {
  background: #f8f8f8;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.div-macle-table-section {
  width: 35%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px 21px;
  overflow: auto;
  gap: 8px;
  text-align: ;
}
.div-macle-graph {
  width: 100%;
  height: 45%;
  flex-grow: 0;
  display: flex;
  justify-content: center;
}
.div-macle-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.table-macle {
  height: 100%;
  width: 100%;
  min-width: 400px;
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed;
}
.table-macle thead {
  background: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 100;
}
.table-macle td {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-left: 2px solid #ececec;
  padding: 9px 12px;
  width: 50px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-macle td:first-child {
  border-left: none;
  width: 100px;
  flex-grow: 2;
}

.div-macleM-background {
  /* background: #f8f8f8; */
  background: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 48px 12px 36px 12px;
  width: 100%;
}
.div-macleM-table-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 6px;
}
.div-macleM-graph {
  margin-top: 12px;
  width: 100%;
  height: 250px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
}
.div-macleM-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.table-macleM {
  height: 100%;
  width: 100%;
  min-width: 300px;
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed;
}
.table-macleM thead {
  background: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 100;
}
.table-macleM td {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-left: 2px solid #ececec;
  padding: 9px 12px;
  width: 20px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-macleM td:first-child {
  border-left: none;
  width: 40px;
  flex-grow: 1;
}
/* ---------------------ShowData----------------------- */
