.div-question-policy-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  margin-top: 20px;
}
.pre-question-policy-request {
  resize: none;
  width: 100%;
  height: 40vh;
  border-radius: 8px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow-y: auto;
  background: #f8f8f8;
  padding: 12px 16px;
}
.div-question-policy-response {
  display: flex;
  justify-content: center;
}
.input-question-policy-checkbox {
  display: none;
}
.input-question-policy-checkbox + label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
