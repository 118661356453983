.div-recruitpage6m-section {
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 48px);
}
.div-recruitpage6m-section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  position: relative;
}
.div-recruitpage6m-section-content {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.div-recruitpage6m-section-content::-webkit-scrollbar {
  display: none;
}
.div-recruitpage6m-reset-section {
  position: relative;
  margin-bottom: 20px;
  z-index: 1100;
}
.div-recruitpage6m-reset {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-top: 6px;
  color: #b4b4b4;
  cursor: pointer;
}
.div-recruitpage6m-reset-toastpopup-section {
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
}
.div-recruitpage6m-bottom {
  width: 100%;
  height: 80px;

  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  padding: 0 20px 20px 20px;
}
.div-recruitpage6m-bottom-gradient {
  position: fixed;
  left: 0;
  bottom: 80px;
  width: 100%;
  height: 18px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1000;
}
.div-recruitpage6m-bottom-section {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 36px;
  border-top: 2px solid #ececec;
  padding-top: 16px;
}
.div-recruitpage6m-top-gradient {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 18px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: 1000;
}
