.div-manage-member-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.div-manage-member-header {
  width: calc(100% - 24px);
  margin: 0 12px;
  background-color: #f8f8f8;
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 20px;
  padding: 12px 16px;
  align-items: center;
  gap: 4px;
}
.div-manage-member-header > input {
  border: none;
  background: none;
  width: 100%;
}

.div-manage-member-content {
  width: calc(100% - 24px);
  height: 44px;
  margin: 0 12px;
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 20px;
  padding: 12px 16px;
  align-items: center;
}
.div-manage-member-content > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
