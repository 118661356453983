.div-club-main-info-section {
  color: #000;
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 100px;
  padding: 0 12px;
}
.div-club-main-info-contents-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}
.div-club-main-info-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #1e1e1e;
  font-size: 16px;
  font-family: Pretendard;
  line-height: 20px;
}
.div-club-main-info-contents-title {
  color: #6a6a6a;
  font-size: 14px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 18px;
}
.div-club-main-info-contents t {
  color: #1e1e1e;
  font-size: 16px;
  font-family: Pretendard;
  line-height: 20px;
  text-decoration-line: underline;
}
.pre-club-main-info-description {
  word-break: break-all;
  white-space: pre-wrap;
  margin-top: 20px;
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--black-50, #f8f8f8);

  color: var(--black-900, #1e1e1e);
  font-size: 16px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
