.timepicker-wrapper {
  display: flex;
  width: 100%;
  padding-left: 36px;
  padding-right: 36px;
  gap: 36px;
  position: relative;
}
.timepicker-middlebox {
  width: 100%;
  height: 36px;
  position: absolute;
  top: 52.5px;
  left: 0px;
  background: #f8f8f8;
  border-radius: 8px;
}
.timepicker-columns {
  display: flex;
  align-items: center;
  gap: 6px;
}
.timepicker-column1 {
  height: 150px;
  width: 50px;
}
.timepicker-column2 {
  height: 150px;
  width: 40px;
}
.timepicker-value {
  color: gainsboro;
  text-align: center;
}
.text-black {
  color: black;
}
.timepicker-word {
  height: 30px;
  z-index: 2;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
