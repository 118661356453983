.div-community-section {
  display: flex;
  justify-content: center;
}

.div-community {
  width: 100%;
  max-width: 480px;
  padding: 12px;
}

.carousel-right {
  position: absolute;
  right: 0px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 91.67%
  );
  width: 24px;
  height: 100%;
  z-index: 100;
}
.carousel-left {
  position: absolute;
  left: 0px;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 91.67%);
  width: 24px;
  height: 100%;
  z-index: 100;
}
.div-community-line {
  width: 100vw;
  height: 8px;
  background: var(--black-50, #f8f8f8);
}
.div-community-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
