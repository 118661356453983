.div-club-main-top-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: linear-gradient(180deg, #1e1e1e 0%, rgba(30, 30, 30, 0) 100%);
  z-index: 1000;
}
.div-club-main-top-section-scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: white;
  z-index: 9000;
  color: #1e1e1e;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
}
.div-club-main-top-club-name {
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
}
.img-club-main-top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 152px;
  object-fit: cover;
}
.div-club-main-scroll-section {
  width: 100%;
  height: 100%;
  position: relative;
  top: 152px;
  background-color: white;
}
.div-club-main-scroll-background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: calc(100vh - 152px);
}
.div-club-main-infobox-section {
  position: absolute;
  top: -12px;
  left: 12px;
  width: calc(100% - 24px);
  height: 102px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  gap: 16px;
}

.img-club-main-infobox-logo {
  width: 60px;
  height: 60px;
  background: #f8f8f8;
  border: 1px solid #ececec;
  border-radius: 100%;
}
.div-club-main-infobox-text-section {
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.div-club-main-menu-section {
  position: absolute;
  top: 95px;
  left: 12px;
  width: calc(100% - 24px);
  display: flex;
  border-bottom: 1px solid #ececec;
}
.div-club-main-menu-section-scrolled {
  position: fixed;
  top: 56px;
  left: 12px;
  width: calc(100% - 24px);
  display: flex;
  border-bottom: 1px solid #ececec;
  background-color: white;
  z-index: 1000;
}
.div-club-main-menu {
  padding: 8px 16px;
}
.div-club-main-menu-checked {
  padding: 8px 16px;
  border-bottom: 2px solid #000000;
}
.div-club-main-contents-section {
  position: absolute;
  top: 151px;
  left: 0;
  width: 100%;
  /* padding: 0 12px; */
}
