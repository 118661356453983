.div-ManageM-section {
  background: white;
  display: flex;
  padding: 20px;
  width: 100%;
}
.div-manageM-section {
  position: absolute;
  top: 48px;
  display: flex;
  width: 100%;
  height: calc(100vh - 48px);
  background-color: #f8f8f8;
}
