.div-question-mbti-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 30px;
}
.div-question-mbti-row {
  display: flex;
  gap: 8px;
}
.div-question-mbti-cell {
  border: 1px solid #cdcdcd;
  width: 100%;
  height: 7.5vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mbti-cell-default {
  background-color: #ffffff;
  color: #1e1e1e;
}
.mbti-cell-selectedE {
  background-color: #ffeaea;
  color: #000000;
}
.mbti-cell-selectedI {
  background-color: #eafffb;
  color: #000000;
}
.mbti-cell-selectedS {
  background-color: #fffeea;
  color: #000000;
}
.mbti-cell-selectedN {
  background-color: #eaf7ff;
  color: #000000;
}
.mbti-cell-selectedT {
  background-color: #fff4ea;
  color: #000000;
}
.mbti-cell-selectedF {
  background-color: #eeffea;
  color: #000000;
}
.mbti-cell-selectedJ {
  background-color: #ffeafd;
  color: #000000;
}
.mbti-cell-selectedP {
  background-color: #f3eaff;
  color: #000000;
}
