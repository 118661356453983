.-outlined-black-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 1px solid #1e1e1e;
}
.-outlined-black-button:hover {
  filter: brightness(100%);
  /* 호버 시 밝기조절 x */
}
.div-loginform-modal-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
/* font-- start */
.-H3 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.-Sub1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.-Sub3 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.-Body4 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6a6a6a;
}
.-Body5 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.-Tag1 {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
/* font-- end */
.div-loginform-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}
#div-loginform-modal {
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}
#div-loginform-wrapper {
  position: relative;
  width: 400px;
  height: 560px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-loginform-close {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  cursor: pointer;
}
#img-loginform-icon {
  width: 105px;
  margin-top: 24px;
}
#p-loginform-welcome-small {
  margin-top: 64px;
  color: var(--Black700);
}
#p-loginform-welcome-big {
  margin-top: 16px;
  text-align: center;
  width: 220px;
  height: 68px;
  line-height: 34px;
}
#img-loginform-loginIntro {
  margin-top: 34px;
  height: 136px;
  width: 205px;
}
#button-loginform-kakaoLogin {
  margin-top: 52px;
  width: 300px;
}
#p-loginform-kakaoLogin-desc {
  color: var(--Black200);
  margin-top: 18px;
}
.p-loginform-statement {
  text-align: center;
  margin-top: 100px;
  line-height: 28px;
}
/*input section-----------------------------*/
#div-loginform-smsAuth-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 45px;
}
.div-loginform-smsAuth-input-row {
  position: relative;
  display: flex;
  gap: 8px;
  margin: 6px 0px;
  width: 300px;
  height: 44px;
}
.input-loginform-smsAuth-input-number {
  width: 100%;
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  flex-shrink: 1;
}
.input-loginform-smsAuth-input-number::placeholder {
  color: #cdcdcd;
}
.input-loginform-smsAuth-input-number:disabled {
  background: #f8f8f8;
  color: #cdcdcd;
}
.p-loginform-smsAuth-input-number-timer {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #ff3d00;
}
#button-loginform-send {
  flex-shrink: 0;
  width: 136px;
}
/*-----------------------------input section*/
.button-loginform-register-next {
  width: 291px;
  padding: 12px 16px;
  border-radius: 8px;
  /* margin-top: 72px; */
  position: absolute;
  bottom: 75px;
}

#div-loginform-basicInfo-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 36px;
}
.div-loginform-basicInfo-row {
  display: flex;
  gap: 16px;
}
.div-loginform-basicInfo-column {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.div-loginform-basicInfo-column > p {
  color: #000000;
}
.div-loginform-basicInfo-column input {
  padding: 12px 16px;
  background: #ffffff;
  border: 0.75px solid #cdcdcd;
  border-radius: 8px;
}
.div-loginform-basicInfo-column input::placeholder {
  color: #cdcdcd;
}
#name {
  width: 140px;
  height: 44px;
}
#birth {
  width: 288px;
  height: 44px;
}
#gender {
  display: flex;
  gap: 4px;
}
#gender button {
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  text-align: center;
  color: #1e1e1e;
}
#gender p {
  width: 32px;
}
.div-loginform-sex-section {
  display: flex;
  gap: 26.3px;
  margin-top: 35.3px;
}
.div-loginform-sex-selection {
  display: flex;
  align-items: center;
  gap: 9px;
}
.div-loginform-sex-selection input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.button-loginform-register-end {
  width: 291px;
  margin-top: 68px;
}
#toastInfo-loginform {
  position: absolute;
  bottom: 132px;
}
#div-loginform-wrapper input {
  cursor: text;
}
