.div-club-main-home-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #000;
  font-size: 16px;
  font-family: Pretendard Variable;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 100px;
  padding: 0 12px;
}
.div-club-main-home-recruit-poster-section {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.div-club-main-home-recruit-poster-section::-webkit-scrollbar {
  display: none;
}
.div-club-main-home-recruit-poster {
  width: 120px;
  height: 120px;
  background: #f8f8f8;
  border: 2px solid #ececec;
  border-radius: 8px;
  flex-shrink: 0;
}
.img-club-main-home-recruit-poster {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.div-club-main-home-recruit {
  position: relative;
}
.div-div-club-main-home-recruit-dday {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--black-900, #1e1e1e);

  color: var(--white, #fff);
  font-size: 10px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.div-club-main-home-recruit-poster-closed {
  width: 120px;
  height: 120px;
  background: #f8f8f8;
  border: 2px solid #ececec;
  border-radius: 8px;
  flex-shrink: 0;
  filter: brightness(75%);
}
.div-club-main-home-recruit-poster-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--black-100, #ececec);
  opacity: 0.4000000059604645;
  background: #1e1e1e;
}
.div-club-main-home-recruit t {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  font-size: 14px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.div-club-main-home-board-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  gap: 12px;

  width: 100%;
  /* height: 182px; */
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 12px;
}
.div-club-main-home-board-article {
  display: flex;
  align-items: center;
  gap: 8px;
}
.div-club-main-home-board-article-rank123 {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  background: rgba(255, 122, 0, 0.3);
  border-radius: 4px;
  color: #ff7a00;
}
.div-club-main-home-board-article-rank {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  background: #ececec;
  border-radius: 4px;
  color: #808080;
}
.div-club-main-home-board-article-title {
  width: calc(100vw - 40px - 28px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.div-club-main-home-photo-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.div-club-main-home-photo-section::-webkit-scrollbar {
  display: none;
}

.div-club-main-home-photo {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 8px;
}
