.ViewImage-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 725px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  z-index: 1000;
  border-radius: 8px;
}
.ViewImage-header {
  width: 100%;
  padding: 22.5px 36px;
  display: flex;
  margin-bottom: 21px;
}
.ViewImage-header img {
  position: absolute;
  right: 36px;
  width: 21px;
  cursor: pointer;
}
.ViewImage-big {
  width: 360px;
  height: 243px;
  /* background-color: #d9d9d9; */
  border-radius: 8px;
}
.ViewImage-big img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.ViewImage-images {
  display: flex;
  gap: 9px;
  margin-top: 36px;
}
.ViewImage-image {
  border: 1px solid #d9d9d9;
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
}
