.div-club-list-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  row-gap: 24px;
  margin-top: 4px;
  margin-bottom: 100px;
}
.div-club-info-section {
  overflow-x: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  position: relative;
}
.div-club-info-top-section {
  height: 170px;
  border-radius: 8px 8px 0 0;
  background-color: #ebebeb;

  display: flex;
  justify-content: center;
}

.div-club-info-bottom-section {
  position: absolute;
  bottom: 0;
  /* height: 70px; */
  height: 50px;
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: white;
}
.img-club-info-badge {
  position: absolute;
  top: 0;
  left: 12px;
  width: 24px;
}
.div-club-info-dday {
  position: absolute;
  top: 6px;
  right: 8px;
  padding: 3px 8px;
  background: #1e1e1e;
  border-radius: 10px;
  color: white;
}
.img-club-info-heart {
  position: absolute;
  bottom: 58px;
  right: 8px;
}
.img-club-info-main {
  object-fit: cover;
  width: 100%;
}
.div-club-info-name {
  width: 100%;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.div-club-info-people {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #6a6a6a;
}

.div-club-list-header-section {
  margin-top: 36px;
  left: 0;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background-color: white;
}
.div-club-list-header-select {
  display: flex;
  gap: 7px;
}
.button-club-list-header-search {
  background: #f8f8f8;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-club-list-header-search {
  background: #f8f8f8;
  width: 100%;
  height: 36px;
  padding: 8px 8px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
}
.input-club-list-header-search {
  width: 90%;
  background: none;
  border: none;
}
