#viewform-modal {
  height: 700px;
  width: 720px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; */
  position: relative;
}

.viewform-arrow-left {
  position: absolute;
  width: 21px;
  top: 50%;
  left: 12px;
  cursor: pointer;
}
.viewform-arrow-right {
  position: absolute;
  width: 21px;
  top: 50%;
  right: 12px;
  cursor: pointer;
}

#viewform-scroll {
  overflow-y: scroll;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
  padding-bottom: 27px;
}
#viewform-scroll input {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
}
#viewform-header {
  width: 100%;
  height: 64.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
#viewform-header img {
  position: absolute;
  right: 36px;
  width: 21px;
  cursor: pointer;
}
.viewform-border {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
}
/* .viewform-border-chip {
  width: 90px;
  padding: 6px 0px;
  text-align: center;
  color: white;
  border-radius: 17px;
} */
.viewform-basicInfo-row {
  width: 100%;
  display: flex;
  width: 600px;
}
.viewform-basicInfo-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  width: 100%;
}
.viewform-stat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 35px;
}
.viewform-stat > p {
  width: 60px;
  text-align: start;
}
.viewform-basicInfo-answer {
  width: 204px;
  height: 35px;
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
}
.viewform-moreInfo-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
  width: 600px;
}
.viewform-moreInfo-question {
  width: 100%;
  display: flex;
  gap: 9px;
  flex-direction: column;
}
.viewform-moreInfo-question-title {
  margin-top: 3px;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
}
.viewform-moreInfo-question > p {
  text-align: start;
}
/* .viewform-moreInfo-question>textarea {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
  resize: none;
  overflow: scroll;
  flex-grow: 1;
} */
.viewform-moreInfo-answer-default {
  padding: 9px 12px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Black200);
  /* width: 558px; */
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;
}
.viewform-moreInfo-answer-image {
  width: 168px;
  height: 168px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f8f8f8;
  object-fit: contain;
  cursor: pointer;
}
.viewform-moreInfo-answer-file {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e1e1e;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  gap: 8px;
  width: 210px;
  height: 33px;
  cursor: pointer;
}
.viewform-moreInfo-answer-file img {
  width: 15px;
  height: 15px;
}
.div-viewform-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 18px 60px;
  border-top: 1px solid #cdcdcd;
}
.viewform-memoBtn {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}
.viewform-memoBtn img {
  width: 18px;
  height: 18px;
}
.div-viewform-score-section {
  width: 100%;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: flex-start;
}
.div-viewform-score {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div-viewform-score-manager-name {
  width: 100%;
  height: 16.5px;
  overflow: hidden;
  text-align: center;
}
.input-viewform-score {
  width: 100%;
  margin-top: 6px;
  padding: 9px 0;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  text-align: center;
}
.input-viewform-score:disabled {
  width: 100%;
  margin-top: 6px;
  padding: 9px 0;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  text-align: center;
}
.img-viewform-footer-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 18px;
  height: 18px;
}
.button-viewform-score {
  width: 100%;
  margin-top: 6px;
}
