.div-manage-main-top-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: linear-gradient(180deg, #1e1e1e 0%, rgba(30, 30, 30, 0) 100%);
  z-index: 1000;
  cursor: pointer;
}
.div-manage-main-top-section-scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: white;
  z-index: 9000;
  color: #1e1e1e;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}
.div-manage-main-top-club-name {
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 24px;
}
.img-manage-main-top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 152px;
  object-fit: cover;
}
.div-manage-main-scroll-section {
  width: 100%;
  height: 100%;
  position: relative;
  top: 152px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  gap: 12px;
}
.div-manage-main-scroll-background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: calc(100vh - 152px);
}
.div-manage-main-infobox-section {
  position: relative;
  top: -12px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 8px;
}
.div-manage-main-infobox-info-wrapper {
  display: flex;
  gap: 16px;
}
.img-manage-info-badge {
  position: absolute;
  top: 0;
  left: 12px;
  width: 24px;
}
.img-manage-main-infobox-logo {
  width: 60px;
  height: 60px;
  background: #f8f8f8;
  border: 1px solid #ececec;
  border-radius: 100%;
}
.div-manage-main-infobox-text-section {
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.div-manage-main-infobox-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.button-manage-main-infobox-change {
  display: flex;
  width: 264px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background-color: #FF7A00;
  color: white;
  width: 100%;
}
.div-manage-main-contents {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
  position: relative;
  top: -12px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.div-manage-main-link-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.img-manage-main-link-icon {
  width: 24px;
  height: 24px;
}
.div-manage-main-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.div-manage-main-link-recruit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.div-manage-main-link-recruit p {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 40px);
}
.chip-manage-main {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 34px;
  height: 20px;
}
.background-orange {
  background-color: #FF7A00;
  color: white;
}
.background-black {
  background-color: black;
  color: white;
}