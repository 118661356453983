.div-recruitpagealways-select-section {
  display: flex;
  gap: 9px;
}
.div-recruitpagealways-select {
  text-decoration: underline;
  cursor: pointer;
}
.div-recruitpagealways-select:hover {
  color: #ff7a00;
}
