.div-boardsetting-section {
  width: 100%;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.div-boardsetting-board {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--black-50, #f8f8f8);
}
.div-boardsetting-board img {
  width: 20px;
}
