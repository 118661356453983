.div-loginformM-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  /* z-index: 1000; */
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.3);
}
#div-loginformM-modal {
  width: 320px;
  height: 557px;
  position: absolute;
  top: calc(50% + 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-loginformM-header {
  width: 100%;
  position: relative;
  padding: 24px 24px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.img-loginformM-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
#img-loginformM-icon {
  margin-top: 2px;
  width: 105px;
  /* align-self: flex-start; */
}
#p-loginformM-welcome-small {
  color: var(--Black700);
  margin-top: 32px;
}
#p-loginformM-welcome-big {
  margin-top: 16px;
  text-align: center;
  width: 220px;
  height: 68px;
  line-height: 34px;
  margin-top: 12px;
}
#img-loginformM-loginIntro {
  height: 136px;
  width: 205px;
  margin-top: 36px;
}
#button-loginformM-kakaoLogin {
  width: 288px;
  height: 44px;
  margin-top: 95px;
}
#p-loginformM-kakaoLogin-desc {
  color: var(--Black200);
}
.p-loginformM-statement {
  text-align: center;
  line-height: 28px;
}
/*input section-----------------------------*/
#div-loginformM-smsAuth-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.div-loginformM-smsAuth-input-row {
  position: relative;
  display: flex;
  gap: 8px;
  margin: 6px 0px;
  width: 300px;
  height: 44px;
}
.input-loginformM-smsAuth-input-number {
  width: 100%;
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  flex-shrink: 1;
}
.input-loginformM-smsAuth-input-number::placeholder {
  color: #cdcdcd;
}
.input-loginformM-smsAuth-input-number:disabled {
  background: #f8f8f8;
  color: #cdcdcd;
}
.p-loginformM-smsAuth-input-number-timer {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #ff3d00;
}
#button-loginformM-send {
  flex-shrink: 0;
  width: 136px;
}
/*-----------------------------input section*/
.button-loginformM-register-next {
  width: 291px;
  padding: 12px 16px;
  border-radius: 8px;
}

.div-loginformM-basicInfo-row {
  display: flex;
  gap: 16px;
}
.div-loginformM-basicInfo-column {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.div-loginformM-basicInfo-column > p {
  color: #000000;
}
.div-loginformM-basicInfo-column input {
  padding: 12px 16px;
  background: #ffffff;
  border: 0.75px solid #cdcdcd;
  border-radius: 8px;
}
.div-loginformM-basicInfo-column input::placeholder {
  color: #cdcdcd;
}
#name {
  width: 140px;
  height: 44px;
}
#birth {
  width: 288px;
  height: 44px;
}
#gender {
  display: flex;
  gap: 4px;
}
#gender button {
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  text-align: center;
  color: #1e1e1e;
}
#gender p {
  width: 32px;
}
.div-loginformM-sex-section {
  display: flex;
  gap: 26.3px;
}
.div-loginformM-sex-selection {
  display: flex;
  align-items: center;
  gap: 9px;
}
.div-loginformM-sex-selection input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.button-loginformM-register-end {
  width: 291px;
}
#toastInfo-loginformM {
  position: absolute;
  bottom: 132px;
}
#div-loginformM-wrapper input {
  cursor: text;
}
