.div-calendar-wrapper {
  position: relative;
}
.div-calendar-opener {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  width: 108px;
  height: 33px;
  padding: 7.5px 12px;
  cursor: pointer;
  background-color: #ffffff;
}
.div-calendar-opener img {
  width: 18px;
}
.div-calendar-modal {
  position: absolute;
  top: 39px;
  /* width: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 8px;
  gap: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 200;
}
.div-calendarQ-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}
.div-calendarEQ-modal {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 9px;
  gap: 9px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  transform: translateX(-50%);
}
.div-calendar-header {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.img-calendar-arrow {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.div-calendar-month {
  display: flex;
  flex-direction: column;
  gap: 3.75px;
}
.div-calendarQ-month {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.div-calendar-week {
  display: flex;
  gap: 6px;
}
.div-calendarQ-week {
  display: flex;
  gap: 8px;
}
.div-calendar-cell {
  width: 15px;
  height: 15px;
  line-height: 15px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  border: none;
  background: none;
}
.div-calendarQ-cell {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-calendarEQ-cell {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-sunday {
  color: #e10000;
}
.calendar-today {
  color: #ff7a00;
}
.calendar-selected {
  background-color: #ff7a00;
  color: #ffffff;
}
.calendar-past {
  opacity: 0.3;
  cursor: default;
}
.cursor-default {
  cursor: default;
}
