.table-wrapper {
  background: #fff;
  overflow: hidden;
}

.recruit-table {
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100vh - 132px - 157.5px);
  display: grid;
  overflow: scroll;
}

.recruit-table .thead-test {
  background: #f8f8f8;
  height: 28.5px;
  position: sticky;
  top: 0;
  z-index: 200;
}
.tbody-test {
  /* overflow: auto; */
}
.recruit-table .td-sticky-01 {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 200;
  border-right: 2px solid #ececec;
  display: flex;
  justify-content: space-around;
}

.td-sticky-02-index {
  width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.recruit-table .td-sticky-02 {
  position: sticky;
  left: 56px;
  background-color: white;
  border-right: 2px solid #ececec;
  z-index: 200;
  display: flex;
  justify-content: space-between;
}
.recruit-table .td-sticky-02-score {
  position: sticky;
  left: 56px;
  background-color: white;
  border-right: 2px solid #ececec;
  z-index: 200;
  display: flex;
  justify-content: center;
}
.recruit-table .td-sticky-03 {
  position: sticky;
  left: 236px;
  background-color: white;
  border-right: 2px solid #ececec;
  z-index: 200;
  display: flex;
  justify-content: space-around;
}
.recruit-table .td-sticky-03-score {
  position: sticky;
  left: 116px;
  background-color: white;
  border-right: 2px solid #ececec;
  z-index: 200;
  display: flex;
  justify-content: space-around;
}
.recruit-table .th-sticky-01 {
  position: sticky;
  left: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  border-right: 2px solid #ececec;
  background-color: #ececec;
}
.recruit-table .th-sticky-02 {
  position: sticky;
  left: 56px;
  border-right: 2px solid #ececec;
  z-index: 300;
  display: flex;
  justify-content: center;
  background-color: #ececec;
}
.recruit-table .th-sticky-03 {
  position: sticky;
  left: 236px;
  border-right: 2px solid #ececec;
  z-index: 300;
  display: flex;
  justify-content: center;
  background-color: #ececec;
}
.recruit-table .th-sticky-04-score {
  position: sticky;
  left: 236px;
  border-right: 2px solid #ececec;
  z-index: 300;
  display: flex;
  justify-content: center;
  background-color: #ececec;
}
.recruit-table .th-sticky-03-score {
  position: sticky;
  left: 116px;
  border-right: 2px solid #ececec;
  z-index: 300;
  display: flex;
  justify-content: center;
  background-color: #ececec;
}
.recruit-table thead,
.recruit-table tbody,
.recruit-table tr {
  display: contents;
}

.recruit-table th {
  position: relative;
  font-size: 9px;
}

.recruit-table th,
.recruit-table td {
  box-sizing: border-box;
  text-align: left;
  padding: 6.8px 9px;
  min-width: 7.5px;
  display: flex;
  align-items: center;
  height: 31.5px;
  font-size: 10.5px;
}

.recruit-table th span,
.recruit-table td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.recruit-table tr td {
  /* border-top: 2px solid #ececec; */
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 2px;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #ececec;
}

.resize-handle:hover {
  background: rgba(255, 122, 0, 0.3);
  width: 4px;
}

.resize-handle:active {
  background: rgba(255, 122, 0, 0.3);
  width: 4px;
}
.div-chip-section {
  display: flex;
  align-items: center;
  gap: 3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.img-table-application {
  height: 18px;
}
.img-table-application:hover {
  filter: brightness(0.5);
}

.img-table-picture {
  height: 18px;
  width: 18px;
}
.img-table-filter {
  height: 15px;
}
.div-table-filter-button-section {
  position: absolute;
  top: 103.5px;
  right: 54px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: right;
  gap: 6px;
  /* margin-bottom: 9px; */
}
.div-table-filter-button-sectionM {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: right;
  gap: 9px;
  margin-bottom: 9px;
}
.button-table-filter {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 6px;

  border-radius: 14px;
  gap: 3px;
}

.button-table-filterM {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 6px;

  border: 1px solid #1e1e1e;
  border-radius: 14px;
  gap: 3px;
}
.button-table-filterM-selected {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 6px;

  border: 1px solid #1e1e1e;
  background-color: #1e1e1e;
  color: white;
  border-radius: 14px;
  gap: 3px;
}
.div-table-features-section {
  position: absolute;
  padding: 9px 12px;
  top: 133.5px;
  right: 114px;
  /* width: 130px; */

  background: #ffffff;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 18px;
}
.p-table-feature:hover {
  cursor: pointer;
  color: rgb(255, 122, 0);
}

.div-table-filter-section {
  position: absolute;
  padding: 9px 18px;
  top: 133.5px;
  right: 54px;
  width: 217.5px;

  background: #ffffff;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}
.div-table-filter-section-01 {
  border-bottom: 1px solid #ececec;
}
.div-table-filter-section-02 {
  max-height: 360px;
  overflow-y: scroll;
}
.div-table-filter-section-quetion {
  display: flex;
  gap: 9px;
  margin: 12px;
  align-items: flex-start;
}
.div-table-filter-section-quetion-gender {
  display: flex;
  gap: 3px;
  align-items: flex-start;
}

.div-table-filter-out-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.img-table-checkbox {
  width: 15px;
  height: 15px;
}

.not-scrollY {
  overflow-y: hidden;
}
.not-scrollX {
  overflow-x: hidden;
}
