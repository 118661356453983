.div-club-main-board-top-section {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  position: relative;
}
.button-club-main-board-top-chip {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  flex-shrink: 0;

  text-align: right;
  font-size: 16px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.button-club-main-board-top-chip-selected {
  background: var(--primary, #ff7a00);
  color: white;
}
.div-club-main-board-section {
  margin-bottom: 100px;
}
.toastpopup-club-main-board-warning {
  display: flex;
  width: calc(100% - 24px);
  margin: 0 12px;
  padding: 11px 10px;
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: center;
  gap: 10px;
}
.div-club-main-board-article-section {
  display: flex;
  width: 100%;
  padding: 0 12px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--black-100, #ececec);
}
.div-club-main-board-article-text {
  display: flex;
  width: 200px;
  flex-grow: 1;
  padding: 12px 0 16px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.img-club-main-board-article {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
}
.div-club-main-board-article-board-name {
  color: var(--black-300, #b4b4b4);
  font-size: 12px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.div-club-main-board-article-category {
  color: var(--black-300, #b4b4b4);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.div-club-main-board-article-title {
  width: 100%;
  height: 18px;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.div-club-main-board-article-detail-section {
  display: flex;
  align-items: center;
  gap: 8px;
}
.div-club-main-board-article-detail {
  color: var(--black-600, #6a6a6a);
  font-size: 12px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 2px;
}
.button-club-main-board-write {
  position: fixed;
  bottom: 68px;
  right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
}
.div-add-board-section {
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}
.div-add-board-header {
  width: 260px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img-add-board-close {
  position: absolute;
  top: 0;
  right: 0;
}
.input-add-board {
  width: 260px;
  height: 40px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--black-200, #cdcdcd);
}
.div-add-page-check-section {
  width: 100%;
  display: flex;
  gap: 8px;
}
.button-add-board-check {
  width: 50%;
  padding: 12px 16px;
}
.button-add-board-complete {
  width: 100%;
  margin-top: 12px;
  padding: 12px 16px;
}

.button-club-main-board-setting {
  display: flex;
  width: calc(100% - 24px);
  margin: 0 12px;
  margin-top: 16px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  border: 1px solid var(--black-900, #1e1e1e);
  background: var(--white, #fff);
}
