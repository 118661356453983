.div-selectm-section {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-selectm-clubs-section {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-selectm-section-info {
  text-align: center;
  position: absolute;
  top: 70px;
  line-height: 150%;
  color: #808080;
}
