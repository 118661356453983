.div-form-drink-container-edit {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 70px;
  height: 200px;
}
.div-form-drink-icons-edit {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 5px;
}
.img-form-drink-glass-edit {
  height: 30px;
}
.img-form-drink-bottle-edit {
  height: 120px;
}
