.div-searchpage-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}
.div-searchpage-header-section {
  flex-shrink: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0 12px;
  background-color: white;
  display: flex;
  align-items: center;
}
.div-searchpage-search {
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 8px 8px 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: var(--black-50, #f8f8f8);
}
.div-searchpage-search input {
  flex-grow: 0;
  width: 100%;
  background: none;
  border: none;
}
.div-searchpage-search img {
  width: 20px;
  height: 20px;
}
.div-searchpage-recent-header {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
}
.div-searchpage-recent {
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--black-50, #f8f8f8);
}
.img-searchpage-close {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  padding: 4px;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
}
